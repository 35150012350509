import React, { useState } from 'react';
import './prize-packs-landing.styles.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import ItemsList from '../items/items-list.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { useSelector } from 'react-redux';
import PrizePacksForm from './prize-packs-form.component';
import PrizePacksList from './prize-packs-list.component';

const PrizePacksLanding = ({
    gameData,
    gameElements,
    playerList
}) => {

    const membership = useSelector(state => state.userData.membership);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const navigate = useNavigate();

    function checkMembershipForNewItem(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    return (
        <div className='prize-packs-landing'>
            <Routes>
                <Route path='' element={
                    <div className='g-card prize-packs-card'>
                        <BackButton cancel={() => navigate(-1)} />
                        <div className="g-space-1"></div>
                        <div className='card-title'>
                            <ImageContainer 
                                src='/images/icons/prizepacks.png' 
                                alt='prize packs icon' 
                                className='head-icon' 
                            />
                            Create and Edit Prize Packs
                            {/* <div className='buttons'>
                                <button 
                                    type='button' 
                                    className='g-button med-btn' 
                                    onClick={() => checkMembershipForNewItem()}
                                >
                                    Create a New Pack
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div> */}
                        </div>
                        <hr />
                        <div className='prize-packs-landing-content'>
                            <PrizePacksList
                                gameData={gameData}
                                gameElements={gameElements} />
                        </div>
                    </div>
                }></Route>
                <Route path='create' element={
                    <PrizePacksForm 
                        gameData={gameData}
                        gameElements={gameElements}
                        playerList={playerList} />
                }></Route>
                <Route path='edit/:packPath' element={
                    <PrizePacksForm
                        gameData={gameData}
                        gameElements={gameElements}
                        playerList={playerList} />
                }></Route>
            </Routes>
            <UpgradeRequiredModal 
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal} />
        </div>
    )
}

export default PrizePacksLanding