import React, { useEffect, useState } from 'react'
import './challenges-landing.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ChallengesList from './challenges-list.component';
import ChallengesForm from './challenges-form.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useSelector } from 'react-redux';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { properCase } from '@/utils/properCase';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const ChallengesLandingPage = ({ gameElements, gameData, playerList }) => {

    const membership = useSelector(state => state.userData.membership);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        checkMembership();
    }, [])

    function checkMembership() {
        const element = 'challenges'
        const res = testMemberRestrictions({'gameData': gameData, 'membership': membership, 'element': element})
        // console.log(res);
        if (res.disabled) {
            let message = ''
            const planRest = membershipRestrictions[membership.membership];
            if (res.disabledReason.includes(element)) {
                message = `While using the ${planRest.title}, you are not able to access ${properCase(element)}.`;
            }
            setShowUpgradeModal(message)
        }
    }

    return (
        <div className='challenges-landing'>
            <div className='g-card challenges-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/challenge.png' className='head-icon' />
                    Create and Edit Challenges</div>
                <hr />
                <div className='challenges-landing-content'>
                    <Routes>
                        <Route path='' element={
                            <ChallengesList 
                                gameElements={gameElements} 
                                gameData={gameData} 
                            />
                        }></Route>
                        <Route path='create' element={
                            <ChallengesForm 
                                gameElements={gameElements} 
                                gameData={gameData} 
                                playerList={playerList} 
                            />
                        }></Route>
                        <Route path='edit/:challengePath' element={
                            <ChallengesForm 
                                gameElements={gameElements} 
                                gameData={gameData} 
                                playerList={playerList} 
                            />
                        }></Route>
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Routes>
                </div>
            </div>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default ChallengesLandingPage