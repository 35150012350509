import React, { useEffect, useRef, useState } from 'react'
import './challenges.styles.scss';
import { switchPlayerMenu } from '../side-menu.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faDoorClosed, faFilter, faHourglass2, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import CategoryFilterModal from '@/components/MANAGE-GAME/create-elements/components/category-filter-modal.component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PlayerChallengeItem from './player-challenge-item.component';
import PlayerChallengePage from './player-challenge-page.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { prereqFilter } from '@/utils/prereqFilter';
import { faCheckCircle, faHand } from '@fortawesome/free-regular-svg-icons';
import { checkElementAccess } from '@/utils/checkElementAccess';

const PlayerChallenges = ({ 
    gameData, 
    gameStats,
    playerData, 
    gameElements, 
    actionItems,
    teamMembers
}) => {

    const [ challengesToDisplay, setChallengesToDisplay ] = useState(null); 
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ typeFilter, setTypeFilter ] = useState('available');
    const catRef = useRef();
    const intRef = useRef();
    const actionItemsRef = useRef({});
    const challengeRef = useRef();
    const navigate = useNavigate();
    
    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'Challenges'
        }
        catRef.current = [];

        return () => {
            // clears access refresh interval
            if (intRef.current) clearInterval(intRef.current);
        }
        
    }, []);

    useEffect(() => {
        actionItemsRef.current = {...actionItems};
    }, [actionItems])

    useEffect(() => {
        if (!gameElements) return;
        displayChallenges();
        if (intRef.current) return;
        intRef.current = setInterval(() => {
            displayChallenges();
        }, 60000);
    }, [gameElements, actionItems, teamMembers])

    useEffect(() => {
        if (!typeFilter || !challengeRef.current) return;
        search();
    }, [typeFilter, challengeRef.current])

    async function displayChallenges() {
        const challengeArr = Object.values(gameElements).filter(e => e.type === 'challenge');
        let filteredArr = [];
        for (let c of challengeArr) {
            const newChallenge = await getFlags(c);
            if (newChallenge) filteredArr.push(newChallenge);
        }
        // return;
        challengeRef.current = [...filteredArr];
        let arr = [];
        for (let c of filteredArr) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
        }
        if (arr.length > 0) {
            arr.sort();
            arr.push('Uncategorized');
        }
        for (let c of arr) {
            let catTitle = c
            if (catTitle) {
                arr[c] = catTitle
            }
        }
        setCategoryList([...new Set(arr)]);
        search();
    }

    async function getFlags(c) {
        let challenge = {...c};
        const sevenDaysFuture = new Date(new Date().setDate(new Date().getDate() + 7)).getTime();
        const sevenDaysPast = new Date(new Date().setDate(new Date().getDate() - 7)).getTime();
        const filterAccess = prereqFilter(challenge, playerData, gameElements);
        // const player = e.playerData;
        // const gameElements = e.gameElements;
        // const elementId = e.elementId;
        // const gameStats = e.gameStats;
        const access = checkElementAccess({
            'playerData': playerData,
            'gameElements': gameElements,
            'elementId': challenge.id,
            'gameStats': gameStats,
            'teamMembers': teamMembers,
            'type': 'Challenge'
        })
        if (!access.hasTeam) return;
        console.log(filterAccess);
        console.log(access);
        let flags = []
        let early = challenge.opt_dateOpen && challenge.opt_dateOpen > new Date().getTime();
        let late = challenge.opt_dateClose && challenge.opt_dateClose < new Date().getTime();

        if (
            !access.access ||
            late || 
            !challenge.opt_available
        ) {
            flags.push({
                'class': 'closed',
                'text':'Closed'
            });
        }
        if (!access.access && challenge.opt_preview) {
            flags.push({
                'class': 'preview',
                'text': 'Preview'
            });
        }
        if (!access.access && early) {
            flags.push({
                'class': 'upcoming',
                'text': 'Upcoming'
            })
        }
        if (!access.access && early && challenge.opt_dateOpen < sevenDaysFuture) {
            flags.push({
                'class': 'coming-soon',
                'text': 'Coming Soon'
            });
        }
        if (access.access && !early && challenge.opt_dateOpen && challenge.opt_dateOpen > sevenDaysPast) {
            flags.push({
                'class': 'new',
                'text': 'New'
            });
        }
        if (access.access && !early && challenge.opt_dateClose && challenge.opt_dateClose < sevenDaysFuture) {
            flags.push({
                'class': 'closing-soon',
                'text': 'Closing Soon'
            });
        }
        // console.log(actionItemsRef.current);
        if (
            actionItemsRef.current && 
            Object.values(actionItemsRef.current).length > 0
        ) {
            const relatedActionItems = Object.values(actionItemsRef.current)
            .filter(i => i.elementId === challenge.id)
            .sort((a,b) => a.ts > b.ts ? 1 : -1)
            for (let r of relatedActionItems) {
                if (!challenge.opt_multiple && r.status === 'approved') {
                    flags.push({
                        'class': 'completed',
                        'text':'Completed'});
                }
                if (r.status === 'pending') {
                    flags = [{
                        'class': 'pending',
                        'text':'Pending'
                    }];
                }
                if (!challenge.opt_multipleTries && r.status === 'declined') {
                    flags.push({
                        'class': 'closed',
                        'text':'Declined'
                    });
                }
                if (challenge.opt_multipleTries && r.status === 'declined') {
                    flags = [{
                        'class': 'new',
                        'text':'Try Again'
                    }];
                }
            }
        }
        // console.log(flags)
        challenge.access = access.access;
        challenge.flags = flags;
        const newChallenge = await getStatus(challenge);
        return newChallenge;
    }

    function getStatus(c) {
        let challenge = {...c};
        let flagClasses = challenge.flags.map(f => f.class);
        let flagText = challenge.flags.map(f => f.text);
        // console.log(flagClasses);
        // console.log(flagText);
        challenge.filter = [];
        if (
            challenge.access && 
            !flagText.includes('Completed') &&
            !flagText.includes('Pending')
        ) {
            challenge.filter.push('available');
        }
        if (flagText.includes('Completed')) {
            challenge.filter.push('completed');
        }
        if (flagText.includes('Pending')) {
            challenge.filter.push('pending');
        }
        if (flagText.includes('Declined')) {
            challenge.filter.push('declined');
        }
        if (flagText.includes('Upcoming')) {
            challenge.filter.push('upcoming');
        }
        if (flagText.includes('Closed')) {
            challenge.filter.push('closed');
        }
        return challenge;
    }

    function search(e) {
        let tempChallenges = [...challengeRef.current]
        .filter(c => c.filter.includes(typeFilter));

        if (!e) {
            setChallengesToDisplay([...tempChallenges]);
            return;
        }
        setChallengesToDisplay(tempChallenges.filter(a => a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e)));
    } 

    function chooseCategory(e) {
        let catList = [...catRef.current];
        if (catList.includes(e)) {
            catList.splice(catList.indexOf(e), 1)
        } else {
            catList.push(e)
        }
        catRef.current = catList;
        setSelectedCategories(catRef.current);
        let tempChallenges = [...challengeRef.current]
        .filter(c => c.filter.includes(typeFilter));
        if (catRef.current.length > 0) {
            setChallengesToDisplay(tempChallenges.filter(a => catRef.current.includes(a.opt_cat) || (a.flags && a.flags.some(f => catRef.current.includes(f)))));
        } else {
            setChallengesToDisplay([...tempChallenges])
        }
    }

    return (
        <div className='player-challenges'>
            <div className='player-challenges-content'>
                <Routes>
                    <Route path='' element={
                        <div className='g-card'>
                            <div className='player-title'>
                                <ImageContainer 
                                    src='/images/icons/win.png' 
                                    alt='complete a challenge icon' 
                                    className='player-title-icon' 
                                />
                                Complete a Challenge
                            </div>
                            <hr />
                            <div className='challenges-grid'>
                                <div className='left-col'>
                                    <div className='challenges-left-menu'>
                                        <button 
                                            className={
                                                `g-button med-btn 
                                                ${typeFilter === 'available' ? 'primary' : ''}`
                                            }
                                            onClick={() => setTypeFilter('available')}>
                                            <FontAwesomeIcon icon={faHand} />
                                            Available
                                        </button>
                                        <button 
                                            className={
                                                `g-button med-btn 
                                                ${typeFilter === 'completed' ? 'primary' : ''}`
                                            }
                                            onClick={() => setTypeFilter('completed')}>
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                            Completed
                                        </button>
                                        <button 
                                            className={
                                                `g-button med-btn 
                                                ${typeFilter === 'pending' ? 'primary' : ''}`
                                            }
                                            onClick={() => setTypeFilter('pending')}>
                                            <FontAwesomeIcon icon={faHourglass2} />
                                            Pending
                                        </button>
                                        <button 
                                            className={
                                                `g-button med-btn 
                                                ${typeFilter === 'declined' ? 'primary' : ''}`
                                            }
                                            onClick={() => setTypeFilter('declined')}>
                                            <FontAwesomeIcon icon={faBan} />
                                            Declined
                                        </button>
                                        <button 
                                            className={
                                                `g-button med-btn 
                                                ${typeFilter === 'upcoming' ? 'primary' : ''}`
                                            }
                                            onClick={() => setTypeFilter('upcoming')}>
                                            <FontAwesomeIcon icon={faStopwatch} />
                                            Upcoming
                                        </button>
                                        <button 
                                            className={
                                                `g-button med-btn 
                                                ${typeFilter === 'closed' ? 'primary' : ''}`
                                            }
                                            onClick={() => setTypeFilter('closed')}>
                                            <FontAwesomeIcon icon={faDoorClosed} />
                                            Closed
                                        </button>
                                    </div>
                                </div>
                                <div className='right-col'>
                                    <div className='player-challenge-list-search'>
                                        <SearchBar search={search} />
                                        <button 
                                            type='button' 
                                            className='g-button med-btn' 
                                            onClick={() => setShowFilterModal(true)}
                                        >
                                            Filter by Category
                                            <FontAwesomeIcon 
                                                icon={faFilter}
                                                className={selectedCategories.length > 0 ? 'full' : 'empty'} 
                                            />
                                        </button>
                                    </div>
                                    <div className="g-space-1"></div>
                                    <div className='player-challenge-list-container'>
                                        {
                                            (categoryList.length > 0 && challengesToDisplay.length > 0) 
                                            ?
                                            categoryList.sort().map(c => {
                                                // console.log(c);
                                                if (
                                                    c != 'Uncategorized' && 
                                                    Object.values(challengesToDisplay)
                                                    .filter(chall => chall.opt_cat === c).length > 0
                                                ) {
                                                    return (
                                                        <div key={c}>
                                                            <h3>{c}</h3>
                                                            <hr/>
                                                            <div className='player-challenge-list'>
                                                                {
                                                                    (challengesToDisplay) &&
                                                                    challengesToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                                                    .filter(chall => chall.opt_cat === c)
                                                                    .map(chall => {
                                                                        return (
                                                                            <div key={chall.id} >
                                                                                <PlayerChallengeItem 
                                                                                    challenge={chall} 
                                                                                    playerData={playerData} 
                                                                                    gameElements={gameElements} 
                                                                                    onClick={() => navigate(chall.path)} 
                                                                                    actionItems={actionItems}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="g-space-2"></div>
                                                        </div>
                                                    )
                                                }
                                                else if (c === 'Uncategorized' && Object.values(challengesToDisplay).filter(chall => !chall.opt_cat).length > 0) {
                                                    return (
                                                        <div key={c}>
                                                            <h3>{c}</h3>
                                                            <hr/>
                                                            <div className='player-challenge-list'>
                                                            {
                                                                (challengesToDisplay) &&
                                                                challengesToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                                                .filter(chall => !chall.opt_cat)
                                                                .map(chall => {
                                                                    return (
                                                                        <div key={chall.id} >
                                                                            <PlayerChallengeItem 
                                                                                challenge={chall} 
                                                                                playerData={playerData} 
                                                                                gameElements={gameElements} 
                                                                                onClick={() => navigate(chall.path)} 
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </div>
                                                        </div>
                                                    )
                                                } 
                                            })
                                            :
                                            (challengesToDisplay && challengesToDisplay.length > 0) 
                                            ?
                                            <div className='player-challenge-list'>
                                                {
                                                    challengesToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                                    .map(c => {
                                                        return (
                                                            <div key={c.id} >
                                                                <PlayerChallengeItem 
                                                                    challenge={c} 
                                                                    playerData={playerData} 
                                                                    gameElements={gameElements} 
                                                                    onClick={() => navigate(c.path)} 
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className='center'>
                                                <h3>No Challenges available...</h3>
                                            </div>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    }></Route>
                    <Route path=':challengePath' element={
                        <PlayerChallengePage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerData={playerData} 
                            actionItems={actionItems} 
                            gameStats={gameStats}
                            teamMembers={teamMembers}
                        />
                    }></Route>
                </Routes>
            </div>
            <div className="g-space-2"></div>
            <CategoryFilterModal
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--primary)'
            />
        </div>
    )
}

export default PlayerChallenges