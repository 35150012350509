import React, { useEffect, useState } from 'react'
import './levels-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import { useNavigate } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import ManagerElementListMenu from '@/components/SHARED/manager-element-list-menu/manager-element-list-menu.component';
import ManagerElementListItem from '@/components/SHARED/manager-element-list-item/manager-element-list-item.component';

const LevelsList = ({ gameElements, gameData }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ filteredElements, setFilteredElements ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ menuCategory, setMenuCategory ] = useState('visible');
    const [ dropdown, setDropdown ] = useState(null);
    const navigate = useNavigate();

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(filteredElements.filter(a => a.type === 'level'))
            return;
        }
        setContentToDisplay(filteredElements.filter(a => a.type === 'level' && (a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e))))
    }
    
    return (
        <div className='levels-list'>
            <ManagerElementListMenu
                gameElements={gameElements}
                type='level'
                setContentToDisplay={setContentToDisplay}
                setDropdown={setDropdown}
                setFilteredElements={setFilteredElements}
                menuCategory={menuCategory}
                setMenuCategory={setMenuCategory} />
            <div className='levels-list-content'>
                <div className='levels-list-search'>
                    <SearchBar search={search} />
                    
                    <button 
                        title='Create a New Level'
                        type='button' 
                        className='g-button med-btn' 
                        onClick={() => checkMembership()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="g-space-1"></div>
                <div className='levels-list-div'>
                    <div className='g-list-item level-list-head element-list-row meta'>
                        <div></div>
                        <div>Name</div>
                        <div>Description</div>
                        <div>Min XP</div>
                        <div></div>
                    </div>
                    <div className='g-space-0-5'></div>
                    <div className='level-list'>
                        {
                            (filteredElements.length > 0)
                            ?
                            filteredElements.sort((a,b) => a.opt_pointMinimum > b.opt_pointMinimum ? 1 : -1)
                            .map(c => (
                                <div key={c.id} >
                                    <ManagerElementListItem
                                        gameElements={gameElements}
                                        gameData={gameData}
                                        element={c}
                                        dropdown={dropdown}
                                        setDropdown={setDropdown}
                                        dataPoint={c.opt_pointMinimum} />
                                </div>
                            ))
                            :
                            <p>No Levels yet...</p>
                        }
                    </div>
                </div>
            </div>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default LevelsList