import React, { useEffect } from 'react';
import './manager-element-list-menu.styles.scss';

const ManagerElementListMenu = ({
    gameElements,
    type,
    itemType,
    setContentToDisplay,
    setDropdown,
    setSelectedCategories,
    setFilteredElements,
    setCategoryList,
    setTypeList,
    menuCategory,
    setMenuCategory, 
    catRef
}) => {

    useEffect(() => {
        if (!gameElements) return;
        setDropdown(null);
        setContentToDisplay([]);
        if (catRef) catRef.current = [];
        if (setSelectedCategories) setSelectedCategories(catRef.current)
        const filtered = Object.values(gameElements)
        .filter(a => 
            a.type === type && 
            (
                (
                    itemType && 
                    a.opt_itemType === itemType
                ) || !itemType
            ) &&
            ((menuCategory === 'visible')
            ? a.opt_visible && a.status === 'active'
            : (menuCategory === 'invisible')
            ? !a.opt_visible && a.status === 'active'
            : (menuCategory === 'deleted')
            ? a.status === 'inactive'
            : false)
        )
        setFilteredElements(filtered)
        if (setCategoryList) setCategoryList([...new Set(filtered.filter(c => c.opt_cat).map(c => c.opt_cat))]);
        if (setTypeList) setTypeList([...new Set(filtered.filter(c => c.opt_itemType).map(c => c.opt_itemType))])
    }, [gameElements, menuCategory])

    return (
        <div className='manager-element-list-menu'>
            <button
                className={`g-button med-btn ${menuCategory === 'visible' ? 'primary' : ''}`}
                onClick={() => setMenuCategory('visible')} >
                Visible
            </button>
            <button
                className={`g-button med-btn ${menuCategory === 'invisible' ? 'primary' : ''}`}
                onClick={() => setMenuCategory('invisible')} >
                Hidden
            </button>
            <button
                className={`g-button med-btn ${menuCategory === 'deleted' ? 'primary' : ''}`}
                onClick={() => setMenuCategory('deleted')} >
                Deleted
            </button>
        </div>
    )
}

export default ManagerElementListMenu