import React, { useEffect, useRef, useState } from 'react';
import './lottery-view.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { getLotteryDataFromDb } from '@/utils/getDataFromDb';
import { off } from 'firebase/database';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import SlotMachine from './slot-machine.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import ConfettiExplosion from 'react-confetti-explosion';
import { getRewardsFromElement } from '@/utils/getRewardsFromElement';
import TransactionItemCard from '@/components/SHARED/tranaction-view/transaction-item-card.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { UseCloudFunction, UseCloudGameplay } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const LotteryView = ({
    gameData,
    gameElements,
    gameStats,
    playerList
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const { lotteryPath } = useParams();
    const [ ticketsFromDb, setTicketsFromDb ] = useState(null);
    const [ lottery, setLottery ] = useState(null);
    const [ tickets, setTickets ] = useState(null);
    const [ playerCounts, setPlayerCounts ] = useState(null);
    const [ picked, setPicked ] = useState([]);
    const [ removed, setRemoved ] = useState({});
    const [ winner, setWinner ] = useState(null);
    const [ playerCategory, setPlayerCategory ] = useState('counts');
    const [ showWinner, setShowWinner ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ rewardsList, setRewardsList ] = useState([]);
    const [ userToEdit, setUserToEdit ] = useState(null);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const dataRef = useRef();
    const navigate = useNavigate();
    let lotteryListener;

    useEffect(() => {

        return () => {
            if (lotteryListener) off(lotteryListener);
        }
    }, []);

    useEffect(() => {
        if (!gameElements || !lotteryPath) return;
        const lot = Object.values(gameElements)
        .filter(e => 
            e.opt_itemType === 'lottery' &&
            e.path === lotteryPath &&
            e.status === 'active'
        )[0]
        if (!lot) navigate(-1);
        setLottery(lot);
        let rewardArr = getRewardsFromElement(lot, gameElements);
        setRewardsList(rewardArr);
    }, [gameElements, lotteryPath])

    useEffect(() => {
        if (!userToEdit) {
            dataRef.current = {}
            setFormData(dataRef.current);
        } else {
            let rem = removed[userToEdit.playerId];
            dataRef.current = {
                'playerId': userToEdit.playerId,
                'removed': rem === 'all' ? ticketsFromDb[userToEdit.playerId] : rem,
            }
            setFormData(dataRef.current);
        }
    }, [userToEdit])

    useEffect(() => {
        if (!lottery || !playerList) return;
        getLotteryDataFromDb({
            'gameId': gameData.gameId,
            'elementId': lottery.id,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            console.log(data);
            setTicketsFromDb({...data});
            let tempData = {...data};
            if (tempData.picked) {
                setPicked({...tempData.picked})
                delete tempData.picked
            }
            let remObj = {};
            if (tempData.removed) {
                remObj = {...tempData.removed};
                setRemoved({...tempData.removed})
                delete tempData.removed
            }
            // setTickets({...tempData});
            let indTicketArr = [];
            let ticketCountArr = [];
            const ticketTotal = Object.values(tempData)
            .reduce((acc, cur) => acc + cur, 0);
            console.log(ticketTotal);
            for (let playerId of Object.keys(tempData)) {
                if (remObj[playerId] === 'all') continue;
                let player = playerList[playerId];
                let count = tempData[playerId];
                if (remObj[playerId]) {
                    count -= remObj[playerId];
                }
                if (count <= 0) continue;
                for (let i=0; i<count; i++) {
                    indTicketArr.push(player);
                }
                player.count = count;
                ticketCountArr.push(player)
            }
            console.log(indTicketArr);
            setTickets(indTicketArr);
            setPlayerCounts(ticketCountArr.sort((a,b) => a.count < b.count ? 1 : -1));
        }
        function handleListener(unsub) {
            lotteryListener = unsub;
        }
    }, [lottery, playerList])

    async function processWinner(e) {
        let tempRemoved = {...removed};
        if (e === 'ignore') {
            setWinner(null);
            setShowWinner(false);
            return;
        }
        setLoading(true); 
        if (e === 'remove-single') {
            if (tempRemoved[winner.playerId]) {
                tempRemoved[winner.playerId]++
            } else {
                tempRemoved[winner.playerId] = 1
            }
            setRemoved({...tempRemoved});
        } else if (e === 'remove-all') {
            tempRemoved[winner.playerId] = 'all'
            setRemoved({...tempRemoved});
        }
        let tempPicked = {...picked};
        const now = new Date().getTime();
        tempPicked[now] = {
            'playerId': winner.playerId,
            'ts': now
        }
        setPicked({...tempPicked})
        setWinner(null);
        setShowWinner(false);
        const res = await UseCloudGameplay(
            'processLotteryPick',
            {
                'appId': userProfile.appId,
                'gameId': gameData.gameId,
                'playerId': winner.playerId,
                'picked': {...tempPicked},
                'removed': {...tempRemoved},
                'element': lottery,
                'distributeRewards': rewardsList.length > 0
            }
        )
        setLoading(false);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again: ${res.error}`);
            return;
        }
    }

    function saveRemovedData(e) {
        if (e < 0) {
            e = 0;
        } else if (e > ticketsFromDb[userToEdit.playerId]) {
            e = ticketsFromDb[userToEdit.playerId]
        }
        dataRef.current = {
            'playerId': userToEdit.playerId,
            'removed': e
        }
        setFormData(dataRef.current);
    }

    async function saveRemovedChange() {
        setLoading(true);
        let tempData = {...removed};
        tempData[userToEdit.playerId] = dataRef.current.removed;
        setRemoved({...tempData});
        const res = await UseCloudFunction(
            'changeLotteryRemoved',
            {
                'appId': userProfile.appId,
                'gameId': gameData.gameId,
                'elementId': lottery.id,
                'removed': tempData
            }
        )
        setLoading(false);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again. (Error: ${res.error})`);
            return;
        }
        setUserToEdit(null);
    }

    return (
        <div className='lottery-view'>
            
            {
                (lottery)
                ?
                <div className='lottery-view-content'>
                    <div className='slot-column'>
                        <div className='lottery-name'>
                            <ImageContainer src={lottery.icon} className='lottery-icon' />
                            {lottery.name}
                        </div>
                        {
                            (lottery.desc) &&
                            <div className='lottery-desc'>
                                {lottery.desc}
                            </div>
                        }
                        <div className='g-space-2'></div>
                        {
                            (tickets) &&
                            // <RandomWheel
                            //     segmentData={segmentData} />
                            <SlotMachine
                                tickets={tickets}
                                setShowWinner={setShowWinner}
                                setWinner={setWinner} />
                        }
                        <div className='g-space-2'></div>
                        {
                            (rewardsList.length > 0) &&
                            <div className='reward-display'>
                                <hr />
                                <h3>Rewards</h3>
                                <div className='reward-list'>
                                    {
                                        rewardsList.map(r => (
                                            <div key={r.id}>
                                                <TransactionItemCard 
                                                    elementData={r}
                                                    small={true} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='info-column'>
                        <div className='player-buttons'>
                            <button 
                                className={`g-button small-btn ${playerCategory === 'counts' && 'primary'}`}
                                onClick={() => setPlayerCategory('counts')} >
                                Counts
                            </button>
                            <button 
                                className={`g-button small-btn ${playerCategory === 'picked' && 'primary'}`}
                                onClick={() => setPlayerCategory('picked')}>
                                Picked
                            </button>
                            <button 
                                className={`g-button small-btn ${playerCategory === 'removed' && 'primary'}`}
                                onClick={() => setPlayerCategory('removed')}>
                                Removed
                            </button>
                        </div>
                        <hr />
                        <div className='player-count-list'>
                        {
                            (playerCategory === 'counts') 
                            ?
                            (playerCounts)
                            ? playerCounts.map(p => {
                                return (
                                    <div 
                                        key={p.playerId} 
                                        className='player-count-item' >
                                        <UserIcon profile={p} tiny={true} noBorder={true} noHover={true} />
                                        <div>{p.displayName}</div>
                                        <div className='count'>{p.count}</div>
                                        <div>
                                            <button 
                                                className='g-button small-btn'
                                                onClick={() => setUserToEdit(p)}>
                                                <FontAwesomeIcon icon={faPen} />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                            : <div className='center-col'>No Tickets Yet...</div>
                            : null
                        }
                        {
                            (playerCategory === 'picked') 
                            ?
                            (Object.values(picked).length > 0)
                            ? Object.values(picked)
                            .sort((a,b) => a.ts < b.ts ? 1 : -1)
                            .map(p => {
                                console.log(p);
                                const player = playerList[p.playerId];
                                return (
                                <div key={p.ts} className='picked-item'>
                                    <UserIcon profile={player} tiny={true} noBorder={true} noHover={true} />
                                    <div>{player.displayName}</div>
                                    <div className='date meta'>{format(new Date(p.ts), "P")}</div>
                                </div>
                            )})
                            : <div className='center-col'>No Winners Yet...</div>
                            : null
                        }
                        {
                            (playerCategory === 'removed') 
                            ?
                            (Object.values(removed).length > 0)
                            ? Object.keys(removed).map(pId => {
                                const p = playerList[pId];
                                if (removed[pId] === 0) return;
                                return (
                                    <div 
                                        key={p.playerId} 
                                        className='player-count-item' >
                                        <UserIcon profile={p} tiny={true} noBorder={true} noHover={true} />
                                        <div>{p.displayName}</div>
                                        <div className='red'>{removed[pId]}</div>
                                        <div>
                                            <button 
                                                className='g-button small-btn'
                                                onClick={() => setUserToEdit(p)}>
                                                <FontAwesomeIcon icon={faPen} />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                            : <div className='center-col'>No Removed Tickets Yet...</div>
                            : null
                        }
                        </div>
                    </div>
                </div>
                :
                <div className='center-col'>
                    <ThreeDotsLoader />
                </div>
                
            }
            <Modal
                show={showWinner}
                cancel={() => setShowWinner(false)}
                cls={rewardsList.length === 0 && 'narrow'} >
                <div className='winner-modal-content'>
                    And the winner is...
                    <div className='g-space-1'></div>
                    {
                        (winner) &&
                        <div className='winner-display'>
                            <UserIcon profile={winner} noBorder={true} noHover={true} />
                            <ConfettiExplosion zIndex={9999} />
                            {winner && winner.displayName}
                            
                        </div>
                    }
                    <div className='g-space-1'></div>
                    
                    <hr />
                    <div className='buttons vertical'>
                        <button 
                            onClick={() => processWinner('remove-single')}
                            className='g-button small-btn'>
                            {
                                (rewardsList.length > 0)
                                ?
                                'Distribute rewards and remove this ticket from the list'
                                :
                                'Remove this ticket from the list'
                            }
                        </button>
                        <button 
                            className='g-button small-btn'
                            onClick={() => processWinner('remove-none')}>
                            {
                                (rewardsList.length > 0)
                                ?
                                'Distribute rewards and allow this ticket to be picked again'
                                :
                                'Allow this ticket to be picked again'
                            }
                        </button>
                        <button 
                            className='g-button small-btn'
                            onClick={() => processWinner('remove-all')}>
                            {
                                (rewardsList.length > 0)
                                ?
                                'Distribute rewards and remove all of this winner\'s tickets'
                                :
                                'Remove all of this winner\'s tickets'
                            }
                        </button>
                        <button 
                            className='g-button small-btn'
                            onClick={() => {processWinner('ignore')}}>
                            Ignore this winner
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={userToEdit}
                cancel={() => setUserToEdit(null)}
                closeButton={true}
                text={`Change User Tickets`}
                cls="narrow" >
                {
                    (userToEdit) &&
                    <div className='edit-modal-content'>
                        <div>Player: {userToEdit.displayName}</div>
                        <div className='existing'>
                            Total Tickets: {ticketsFromDb[userToEdit.playerId]}
                        </div>
                        <div className='field small'>
                            <label>Tickets removed:</label>
                            <input 
                                type='number'
                                value={formData.removed ?? 0}
                                min={0}
                                max={ticketsFromDb[userToEdit.playerId]}
                                onChange={(e) => saveRemovedData(Number(e.target.value))} />
                        </div>
                        <hr />
                        <div className='buttons'>
                            <button 
                                className='g-button'
                                onClick={() => setUserToEdit(null)}>
                                Cancel
                            </button>
                            <button
                                className='g-button primary'
                                onClick={() => saveRemovedChange()}>
                                Save Changes
                            </button>
                        </div>
                        
                    </div>
                }
            </Modal>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default LotteryView