import React, { useEffect, useRef, useState } from 'react'
import './badges-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faEllipsisV, faFilter, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VisibilityButton from '../components/visibility-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import ManagerElementListItem from '@/components/SHARED/manager-element-list-item/manager-element-list-item.component';
import ManagerElementListMenu from '@/components/SHARED/manager-element-list-menu/manager-element-list-menu.component';

const BadgesList = ({ 
    gameElements, 
    gameData 
}) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ filteredElements, setFilteredElements ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const [ menuCategory, setMenuCategory ] = useState('visible');
    const [ dropdown, setDropdown ] = useState(null);
    const catRef = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        chooseCategory();
    }, [filteredElements])

    function checkMembership() {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function chooseCategory(e) {
        if (e) {
            let catList = [...catRef.current];
            if (catList.includes(e)) {
                catList.splice(catList.indexOf(e), 1)
            } else {
                catList.push(e)
            }
            catRef.current = catList;
            setSelectedCategories(catRef.current);
        }
        if (catRef.current.length > 0) {
            setContentToDisplay(filteredElements.filter(a => catRef.current.includes(a.opt_cat)))
        } else {
            setContentToDisplay(filteredElements)
        }
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(filteredElements)
            return;
        }
        setContentToDisplay(filteredElements.filter(a => a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e)))
    }
    
    return (
        <div className='badge-list'>
            <ManagerElementListMenu
                gameElements={gameElements}
                type='badge'
                setContentToDisplay={setContentToDisplay}
                setDropdown={setDropdown}
                setSelectedCategories={setSelectedCategories}
                setFilteredElements={setFilteredElements}
                setCategoryList={setCategoryList}
                menuCategory={menuCategory}
                setMenuCategory={setMenuCategory}
                catRef={catRef} />
            {/* <div className="g-space-2"></div> */}
            <div className='badge-list-div'>
                <div className='badge-list-search'>
                    <SearchBar search={search} />
                    <button 
                        title='Filter by Category'
                        type='button' 
                        className='g-button med-btn' 
                        onClick={() => setShowFilterModal(true)}>
                        <FontAwesomeIcon 
                            icon={faFilter}
                            className={selectedCategories.length > 0 ? 'full' : 'empty'}
                        />
                    </button>
                    <button 
                        title='Create a New Badge'
                        type='button' 
                        className='g-button med-btn' 
                        onClick={() => checkMembership()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="g-space-1"></div>
                <div className='badge-list-div'>
                    <div className='g-list-item badge-list-head element-list-row meta'>
                        <div></div>
                        <div>Badge Name</div>
                        <div>Description</div>
                        <div>Purpose</div>
                        <div></div>
                    </div>
                    <div className='g-space-0-5'></div>
                    <div className='badge-list'>
                        {
                            (contentToDisplay.length > 0)
                            ?
                            contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => (
                                <div key={c.id}>
                                    <ManagerElementListItem
                                        gameElements={gameElements}
                                        element={c}
                                        gameData={gameData}
                                        dropdown={dropdown}
                                        setDropdown={setDropdown}
                                        dataPoint={c.opt_purposeGroup ? 'Groups' : 'Players'} />
                                </div>
                                // <div key={c.id} className='g-list-item row'>
                                //     <ImageContainer src={c.icon} alt={`${c.name} badge icon`} className={`badge-icon ${c.status !== 'active' ? 'disabled' : ''}`} />
                                //     <div className='badge-name'>{c.name}</div>
                                //     <div className='meta badge-purpose'>{c.opt_purposeGroup ? 'Groups' : 'Players'}</div>
                                //     <div className='meta'>{format(new Date(c.ts_created), 'P')}</div>
                                //     <div className='buttons'>    
                                //         {/* <VisibilityButton element={c} /> */}
                                //         {
                                //             (c.status === 'active')
                                //             ?
                                //             <>
                                //             <button 
                                //                 className='g-button med-btn' 
                                //                 title='View badge data'
                                //                 onClick={() => navigate(`/manage/${gameData.path}/elements/data/${c.path}`)}>
                                //                 <FontAwesomeIcon icon={faChartPie} />
                                //             </button>
                                //             <button className='g-button med-btn' title='Edit this badge' onClick={() => navigate(`edit/${c.path}`)}  ><FontAwesomeIcon icon={faPen} /></button>
                                //             <button
                                //                 className='g-button med-btn'>
                                //                 <FontAwesomeIcon icon={faEllipsisV} />
                                //             </button>
                                //             <div 
                                //                 className={`dropdown ${dropdown === c.id ? 'active' : ''}`}
                                //                 >
                                                
                                //             </div>
                                //             </>
                                //             :
                                //             <button className='g-button med-btn'>
                                //                 Restore
                                //             </button>
                                //         }
                                //     </div>
                                // </div>
                            ))
                            :
                            <p>No badges yet...</p>
                        }
                    </div>
                </div>
            </div>
            
            <CategoryFilterModal 
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--badge-color)'
            />

            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default BadgesList