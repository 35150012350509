import React, { useEffect, useState } from 'react'
import './new-reward-modal.styles.scss'

import Modal from '@/components/modal/modal.component'
import { useNavigate } from 'react-router-dom'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { setNewStuffCount } from '@/state/slices/contentSlice'
import { properCase } from '@/utils/properCase'
import { UseCloudGameplay } from '@/utils/firebase.utils'

const NewRewardModal = ({ 
    gameData, 
    playerData, 
    playerActivity,
    gameElements
}) => {
    
    const [ show, setShow ] = useState(null);
    const [ alertType, setAlertType ] = useState(null);
    const [ modalImage, setModalImage ] = useState(null);
    const [ modalText, setModalText ] = useState(null);
    const [ appliedElement, setAppliedElement ] = useState(null);
    const [ activityId, setActivityId ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!playerData || !playerActivity) return;
        
        let savedNewRewardCount = window.localStorage.getItem(`${playerData.playerId}_newRewardCount`);
        if (!savedNewRewardCount) {
            savedNewRewardCount = 0;
            window.localStorage.setItem(`${playerData.playerId}_newRewardCount`, 0);
        }

        if (playerData.newStuff && playerData.newStuff.length > 0) {
            
            const rewardList = Object.values(playerActivity)
            .filter(a => 
                playerData.newStuff.includes(a.id)
            );
            console.log(rewardList);
            setNewStuffCount(rewardList.length)
            if (rewardList.length > savedNewRewardCount) {
                if (rewardList.filter(r => 
                        r.display === 'reward' &&
                        r.type === 'approvedItem'
                    ).length > 0
                ) {
                    let activity = rewardList.filter(r => 
                        r.display === 'reward' &&
                        r.type === 'approvedItem'
                    )[0];
                    setAlertType('approvedItemWithReward');
                    setAppliedElement(activity)
                }
                else if (rewardList.filter(r => 
                        r.type === 'approvedItem'
                    ).length > 0
                ) {
                    let activity = rewardList.filter(r => 
                        r.type === 'approvedItem'
                    )[0];
                    setAlertType('approvedItem');
                    setActivityId(activity.id);
                    setAppliedElement(activity);
                }
                else if (rewardList.filter(r => 
                        r.display === 'reward' &&
                        r.type === 'secretCode'
                    ).length > 0
                ) {
                    let activity = rewardList.filter(r => 
                        r.display === 'reward' &&
                        r.type === 'secretCode'
                    )[0];
                    console.log(activity);
                    setAppliedElement(activity);
                    setAlertType('codeWithReward');
                }
                else if (rewardList.filter(r =>
                        r.display === 'reward'
                    ).length > 0
                ) {
                    setAlertType('rewardWithReward')
                }
                else if (rewardList.filter(r => 
                        r.display === 'achievement'
                    ).length > 0
                ) {
                    setAlertType('achievementWithReward');
                } 
                else if (rewardList.filter(r => 
                        r.display === 'prizepack'
                    ).length > 0
                ) {
                    setAlertType('prizepackWithReward');
                }
                return;
            }
        }
        
    }, [playerData, playerActivity])

    useEffect(() => {
        if (
            alertType === 'approvedItem' ||
            alertType === 'approvedItemWithReward'
        ) {
            setModalImage(appliedElement ? appliedElement.icon : null);
            setModalText('An item you submitted has been ACCEPTED!');
            setShow(true);
        } else if (alertType === 'codeWithReward') {
            setModalImage(appliedElement ? appliedElement.icon : null);
            setModalText('You found a secret code!');
            setShow(true);
        }
        else if (alertType === 'rewardWithReward') {
            setModalImage('/images/icons/treasure-cl.png');
            setModalText('You have New Rewards to Discover!');
            setShow(true);
        } else if (alertType === 'achievementWithReward') {
            setModalImage('/images/icons/achievement_frame.gif');
            setModalText('You have New Achievements!<br />Check out what you have earned.');
            setShow(true);
        } else if (alertType === 'prizepackWithReward') {
            setModalImage('/images/icons/prizepack_frame.gif');
            setModalText('You Have New Prize Packs to Open!');
            setShow(true);
        }
        
    }, [alertType, appliedElement])

    function goToRewards() {
        window.localStorage.setItem(`${playerData.playerId}_newRewardCount`, playerData.newStuff.length);
        setShow(null);
        navigate('rewards');
    }

    function setLater(e) {
        window.localStorage.setItem(`${playerData.playerId}_newRewardCount`, playerData.newStuff.length);
        setShow(null);
    }

    async function handleOk() {
        const data = {
            'gameId': gameData.gameId,
            'playerId': playerData.playerId,
            'rewardIdArr': [activityId]
        }
        const res = await UseCloudGameplay('markRewardAsViewed', data);    
        console.log(res);
        setShow(false);
        window.localStorage.setItem(`${playerData.playerId}_newRewardCount`, playerData.newStuff.length);
    }

    return (
        <div>
            <Modal show={show} cancel={() => setShow(null)} cls='narrow'>
                <div className='modal-children new-reward-modal'>
                    <ImageContainer src={modalImage} alt='reward notification icon' className='treasure-gif' />
                    <h2><div dangerouslySetInnerHTML={{__html: modalText}}></div></h2>
                    <hr />
                    {
                        (alertType && alertType.includes('WithReward'))
                        ?
                        <div className="buttons">
                            <button className='g-button' onClick={() => setLater()}>Later</button>
                            <button className="g-button primary" onClick={() => goToRewards()}>Go to My Rewards!</button>
                        </div>
                        :
                        <div className="buttons">
                            <button className="g-button primary" onClick={() => handleOk()}>Ok</button>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default NewRewardModal