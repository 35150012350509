import React, { useEffect, useRef, useState } from 'react';
import './item-selector.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import QuantityInput from '@/components/PLAYER/store/store-components/quantity-input.component';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import { properCase } from '@/utils/properCase';

const ItemSelector = ({ 
    type, 
    formData, 
    saveData, 
    gameElements, 
    gameData, 
    headText, 
    inst, 
    playerId,
    saveKey 
}) => {

    const membership = useSelector(state => state.userData.membership);
    const [ availableItems, setAvailableItems ] = useState([]);
    const [ itemsToDisplay, setItemsToDisplay ] = useState([]);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const searchRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        let avail = Object.values(gameElements)
        .filter(e => e.type === 'item' && e.status === 'active')
        setAvailableItems(avail);
    }, [gameElements])

    useEffect(() => {
        if (!formData) return;
        if (formData[saveKey] && 
            (
                saveKey === 'opt_earnedItems' ||
                saveKey === 'opt_earnedPrizePacks'
            )
        ) {
            setSelectedItems({...formData[saveKey]});
        } else if (formData[saveKey] &&
            (
                saveKey === 'opt_prereqItems' ||
                saveKey === 'opt_prizes' ||
                saveKey === 'opt_itemList'
            )
        ) {
            setSelectedItems([...formData[saveKey]])
        }
    }, [formData])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate(`/manage/${gameData.path}/elements/items/create`);
    }

    function chooseMultipleOption(id) {
        let tempItems = [...selectedItems];
        if (tempItems.includes(id)) {
            tempItems.splice(tempItems.indexOf(id), 1)
        } else {
            tempItems.push(id);
        }
        setSelectedItems([...tempItems]);
        saveData(saveKey, [...tempItems]);
    }

    function changeItemQuantity(e, id) {
        let selectedItemObj = {};
        if (!e) {
            if (formData[saveKey] && formData[saveKey][id]) {
                selectedItemObj = {...formData[saveKey]};
                delete selectedItemObj[id];
                saveData(saveKey, selectedItemObj, playerId);
            }
            return;
        };
        if (formData[saveKey]) {
            selectedItemObj = {...formData[saveKey]};
        }
        if (selectedItemObj[id]) {
            let selItem = {...selectedItemObj[id]};
            selItem.quantity = e;
            selectedItemObj[id] = {...selItem};
        } else {
            selectedItemObj[id] = {
                'id': id,
                'quantity': e
            }
        }
        saveData(saveKey, selectedItemObj, playerId);
    }

    return (
        <div className={`item-selector ${type === 'distribute' || type === 'prereq' ? '' : 'g-list-item form-section'}`}>
            {
                (headText) &&
                <>
                <h3>{headText}<span className='required'>*</span></h3>
                <hr />
                </>
            }
            {
                (inst) &&
                <p>{inst}<span className='required'>*</span></p>
            }
            <ItemSearchBar
                availableItems={availableItems}
                setItemsToDisplay={setItemsToDisplay}
            />
            <div className="g-space-0-5"></div>
            <div className={`g-list-item ${type === 'distribute' ? 'distribute-select' : 'multiple-select'}`}>
                <div className='item-list'>
                {
                    (loading)
                    ?
                    <div className='center-col'>
                        <ThreeDotsLoader />
                    </div>
                    :
                    (itemsToDisplay.length > 0) 
                    ?
                    itemsToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    .map(i => (
                        (type === "distribute")
                        ?
                        <div 
                            key={i.id}
                            className='distribute-item-option'
                        >
                            <div className='icon-container'>
                                <ImageContainer 
                                    src={i.icon} 
                                    alt={`${i.name} item icon`} 
                                />
                            </div>
                            <div>{i.name}</div>
                            <div className='meta'>{i.opt_cat ?? ''}</div>
                            <div className='meta'>{properCase(i.opt_itemType)}</div>
                            <div>
                                <QuantityInput
                                    initialValue={formData[saveKey] && formData[saveKey][i.id] ? formData[saveKey][i.id].quantity : 0}
                                    min={0}
                                    max={i.opt_limitInd ?? 20}
                                    onQuantityChange={e => changeItemQuantity(e, i.id)} 
                                />
                            </div>
                        </div>
                        :
                        <div 
                            key={i.id} 
                            className={`multiple-select-option ${
                                selectedItems.includes(i.id) 
                                ? 
                                'active' 
                                : 
                                ''
                            }`} 
                            onClick={() => chooseMultipleOption(i.id)}
                        >
                            <div className='item-info'>
                                <div className='icon-container'>
                                    <ImageContainer src={i.icon} alt={`${i.name} item icon`} />
                                </div>
                                <div>{i.name}</div>
                                <div className='meta'>{i.opt_cat ?? ''}</div>
                                <div className='meta'>{properCase(i.opt_itemType)}</div>
                                {
                                    type === 'sale' ?
                                    <div className='cost'>
                                        <ImageContainer 
                                            src='/images/icons/coin.png'
                                            className='coin-icon' 
                                        />
                                        {i.opt_marketPrice}
                                    </div>
                                    : 
                                    <div></div>
                                }
                            </div>
                        </div>
                    ))
                    :
                    <span>No Items...</span>
                }
                </div>
            </div>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default ItemSelector