import React, { useEffect, useState } from 'react';
import './reward-item-clicked.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import HiddenText from '@/components/SHARED/hidden-text/hidden-text.component';
import { format } from 'date-fns';

const RewardItemClicked = ({ 
    reward,
    handleRewardModalOpen
}) => {

    const [ backgroundImage, setBackgroundImage ] = useState(null);
    
    useEffect(() => {
        if (!reward) return;
        setBackgroundImage(
            (reward.display === 'reward')
            ?
            '/images/icons/treasure-op.png'
            :
            (reward.display === 'achievement')
            ?
            '/images/icons/achievement_frame.gif'
            :
            (reward.display === 'prizepack')
            ?
            '/images/icons/prizepack_frame.gif'
            :
            (reward.display === 'purchase')
            ?
            '/images/icons/shopping-cart-frame.png'
            :
            ''
        )
    }, [reward])

    return (
        <div className='reward-item-clicked' id={`reward-${reward.ts}-div`} >
            <table className='treasure-table'>
                <tbody>
                    <tr>
                        <td className='opened-treasure-td'>
                            <ImageContainer 
                                src={backgroundImage}
                                alt='open rewards icon' 
                                className='opened-treasure' />
                            <ImageContainer 
                                src={reward.icon}
                                alt='activity icon' 
                                className='activity-icon' />
                        </td>
                    </tr>
                    <tr>
                        <td className='text-td'>
                            <span className='text'>
                                <span className='desc'>
                                    {reward.event}
                                </span>
                                <br />
                                {
                                    reward.type === 'secretCode' ? 
                                    <HiddenText 
                                        text={reward.title} 
                                        hidden={true} /> : 
                                    <span>{reward.title}<br /></span>
                                }
                                
                                <span className='desc'>
                                    Earned {format(new Date(reward.ts), 'Pp')}
                                </span>
                            </span>
                            {
                                (reward.data && Object.values(reward.data).length > 0) &&
                                <>
                                    <div className="g-space-20"></div>
                                    <div className='center-col'>
                                        <button 
                                            className='g-button small-btn' 
                                            onClick={() => handleRewardModalOpen(reward.id)}>
                                            View Rewards
                                        </button>
                                    </div>
                                </>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default RewardItemClicked