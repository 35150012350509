import React from 'react'
import './user-icon.styles.scss'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { useSelector } from 'react-redux'

const UserIcon = ({ profile, reverse, white, tiny, small, large, xlarge, noHover, noBorder, planIcon }) => {

    const membership = useSelector(state => state.userData.membership);

    return (
        <div className={`user-icon-div ${reverse ? 'reverse' : ''}`}>
            {
                (profile.icon) 
                ?
                <ImageContainer 
                    src={profile.icon.url ?? profile.icon} 
                    className={`user-icon 
                        ${white ? ' white' : ''} 
                        ${tiny ? ' tiny' : ''}
                        ${small ? ' small' : ''}
                        ${large ? ' large' : ''} 
                        ${xlarge ? ' xlarge' : ''} 
                        ${noHover ? ' no-hover' : ''}
                        ${noBorder ? 'no-border' : ''}`} 
                    alt="profile-pic"  
                    referrerPolicy="no-referrer" 
                />
                :
                <div 
                    className={`user-icon 
                        ${white && ' white'} 
                        ${tiny ? ' tiny' : ''}
                        ${small && ' small'}
                        ${large && ' large'} 
                        ${xlarge && ' xlarge'} 
                        ${noHover && ' no-hover'}
                        ${noBorder ? 'no-border' : ''}`
                    }
                >
                    <div 
                        className='grad' 
                        style={{'background': profile.gradient}}
                    >
                        {profile.fName[0].toUpperCase()}
                    </div>
                </div>
            }
            {
                (membership && planIcon) &&
                <div className='member-icon-div'>
                <ImageContainer 
                    src={
                        (membership.membership === 0) ?
                        '/images/icons/free.png' :
                        (membership.membership === 1) ?
                        '/images/icons/pro.png' :
                        (membership.membership === 2) ?
                        '/images/icons/premium.png' :
                        (membership.membership === 3) ?
                        '/images/icons/enterprise.png' :
                        null
                    }
                    title={
                        (membership.membership === 0) ?
                        'Free Member' :
                        (membership.membership === 1) ?
                        'Pro Member' :
                        (membership.membership === 2) ?
                        'Premium Member' :
                        (membership.membership === 3) ?
                        'Enterprise Member' :
                        null
                    }
                    className='membership-icon'  
                />
                </div>
            }
        </div>
    )
}

export default UserIcon