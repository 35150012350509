import { format } from "date-fns";
import { RandomString } from "./randomString";

export function checkElementAccess({
    playerData,
    gameElements,
    elementId,
    gameStats,
    teamMembers,
    type,
    bool
}) {
    let access = true;
    let reasonsArr = [];
    const now = new Date().getTime();
    const element = gameElements[elementId];
    console.log(teamMembers);
    if (!element) return null;
    let remainingObj = {
        remainingInd: null,
        remainingIndTotal: null,
        remainingTotal: null,
        remainingMultiple: null,
        remainingTries: null,
    }
    let hasTeam = true;

    if (element.status !== 'active' || !element.opt_visible) {
        access = false;
        reasonsArr.push({
            'text': `This ${type ?? 'element'} has been removed from the game.`,
            'class': 'red',
            'id': RandomString(10)
        });
        let response = {
            'arr': reasonsArr,
            'access': access,
            'hasTeam': hasTeam
        }
        return response;
    }

    if (!element.opt_available) {
        access = false;
        reasonsArr.push({
            'text': `This ${type ?? 'element'} is not currently available.`,
            'class': 'red',
            'id': RandomString(10)
        });
        let response = {
            'arr': reasonsArr,
            'access': access,
            'hasTeam': hasTeam
        }
        return response;
    }

    // check item count access
    if (element.opt_limitInd) {
        if (
            playerData.items && 
            playerData.items[elementId]
        ) {
            if (playerData.items[elementId].count >= element.opt_limitInd) {
                access = false;
                reasonsArr.push({
                    'text': `You have reached the total amount you are allowed for this ${type ?? 'element'}.`,
                    'class': 'red',
                    'id': RandomString(10)
                });
            } else {
                remainingObj.remainingInd = element.opt_limitInd - playerData.items[elementId].count;
                reasonsArr.push({
                    'text': `You can own ${remainingObj.remainingInd} more of this ${type ?? 'element'}.`,
                    'class': null,
                    'id': RandomString(10)
                });
            }
        } else {
            remainingObj.remainingInd = element.opt_limitInd;
            reasonsArr.push({
                'text': `You can own ${remainingObj.remainingInd} more of this ${type ?? 'element'}.`,
                'class': null,
                'id': RandomString(10)
            });
        }
    }   

    // check individual total
    if (element.opt_limitIndTotal) {
        if (
            playerData.items && 
            playerData.items[elementId]
        ) {
            if (playerData.items[elementId].total >= element.opt_limitIndTotal) {
                access = false;
                reasonsArr.push({
                    'text': `You have reached the total amount you are allowed for this ${type ?? 'element'}.`,
                    'class': null,
                    'id': RandomString(10)
                });
            } else {
                remainingObj.remainingIndTotal = element.opt_limitIndTotal - playerData.items[elementId].total;
                reasonsArr.push({
                    'text': `You can ${
                        element.opt_itemType === 'contribution' ? 
                        `contribute to this item ${remainingObj.remainingIndTotal} more times.` :
                        element.opt_itemType === 'realworld' || element.opt_itemType === 'prizepack' ?
                        `purchase this item ${remainingObj.remainingIndTotal} more times.` :
                        `own ${remainingObj.remainingIndTotal} more of this item.`}`,
                    'class': null,
                    'id': RandomString(10)
                });
            }
        } else {
            remainingObj.remainingIndTotal = element.opt_limitIndTotal;
            reasonsArr.push({
                'text': `You can ${
                    element.opt_itemType === 'contribution' ? 
                    `contribute to this item ${remainingObj.remainingIndTotal} more times.` :
                    element.opt_itemType === 'realworld' || element.opt_itemType === 'prizepack' ?
                    `purchase this item ${remainingObj.remainingIndTotal} more times.` :
                    `own ${remainingObj.remainingIndTotal} more of this item.`}`,
                'class': null,
                'id': RandomString(10)
            });
        }
    }   

    // check limitTotal
    if (element.opt_limitTotal) {
        if (
            gameStats && 
            gameStats[elementId]
        ) {
            if (gameStats[elementId] >= element.opt_limitTotal) {
                access = false;
                reasonsArr.push({
                    'text': `This ${type ?? 'element'} has reached its total allowed in the game.`,
                    'class': 'red',
                    'id': RandomString(10)
            });
            } else {
                remainingObj.remainingTotal = element.opt_limitTotal - gameStats[elementId];
                reasonsArr.push({
                    'text': `There are ${remainingObj.remainingTotal} more of this ${type ?? 'element'} available.`,
                    'class': null,
                    'id': RandomString(10)
                });
            }
        } else {
            remainingObj.remainingTotal = element.opt_limitTotal;
            reasonsArr.push({
                'text': `There are ${remainingObj.remainingTotal} more of this ${type ?? 'element'} available.`,
                'class': null,
                'id': RandomString(10)
            });
        }
    }

    if (element.opt_multipleLimit) {
        if (
            playerData.challenges && 
            playerData.challenges[elementId]
        ) {
            if (playerData.challenges[elementId].count >= element.opt_multipleLimit) {
                access = false;
                reasonsArr.push({
                    'text': `You have reached the total amount of times you are allowed to complete this ${type ?? 'element'}.`,
                    'class': 'red',
                    'id': RandomString(10)
                });
            } else {
                remainingObj.remainingMultiple = element.opt_multipleLimit - playerData.challenges[elementId].count;
                reasonsArr.push({
                    'text': `Completions remaining: ${remainingObj.remainingMultiple}`,
                    'class': null,
                    'id': RandomString(10)
                })
            }
        } else {
            remainingObj.remainingMultiple = element.opt_multipleLimit;
            reasonsArr.push({
                'text': `Completions remaining: ${remainingObj.remainingMultiple}`,
                'class': null,
                'id': RandomString(10)
            });
        }
    }

    if (element.opt_multipleTriesLimit) {
        if (
            playerData.challenges && 
            playerData.challenges[elementId]
        ) {
            if (playerData.challenges[elementId].tries >= element.opt_multipleTriesLimit) {
                access = false;
                reasonsArr.push({
                    'text': `You have reached the total amount of times you are allowed to attempt this ${type ?? 'element'}.`,
                    'class': 'red',
                    'id': RandomString(10)
                });
            } else {
                remainingObj.remainingTries = element.opt_multipleTriesLimit - playerData.challenges[elementId].tries;

                reasonsArr.push({
                    'text': `Attempts remaining: ${remainingObj.remainingTries}`,
                    'class': null,
                    'id': RandomString(10)
                });
            }
        } else {
            remainingObj.remainingTries = element.opt_multipleTriesLimit;
            reasonsArr.push({
                'text': `Attempts remaining: ${remainingObj.remainingTries}`,
                'class': null,
                'id': RandomString(10)
            });
        }
    }

    // check open date
    if (
        element.opt_dateOpen &&
        now < element.opt_dateOpen
    ) {
        reasonsArr.push({
            'text': `The ${type ?? 'element'} is not available until ${format(new Date(element.opt_dateOpen), 'Pp')}.`,
            'class': 'red',
            'id': RandomString(10)
        });
        access = false;
    }

    // check close date
    if (
        element.opt_dateClose &&
        now > element.opt_dateClose
    ) {
        reasonsArr.push({
            'text': `The ${type ?? 'element'} closed on ${format(new Date(element.opt_dateClose), 'Pp')}.`,
            'class': 'red',
            'id': RandomString(10)
        });
        access = false;
    }
    
    // check points
    if (
        element.opt_prereqPoints &&
        playerData.points < element.opt_prereqPoints
    ) {
        reasonsArr.push({
            'text': `You do not have the required ${element.opt_prereqPoints} XP.`,
            'class': 'red',
            'id': RandomString(10)
        });
        access = false;
    }

    // check level
    if (
        element.opt_prereqLevel &&
        (
            !playerData.levels ||
            !playerData.levels[element.opt_prereqLevel]
        )
    ) {
        reasonsArr.push({
            'text': `You have not reached the ${gameElements[element.opt_prereqLevel].name} Level yet.`,
            'class': null,
            'id': RandomString(10)
        });
        access = false;
    }
    
    // check badges
    if (element.opt_prereqBadges) {
        for (let badgeId of element.opt_prereqBadges) {
            if (
                !playerData.badges ||
                !playerData.badges[badgeId]
            ) {
                reasonsArr.push({
                    'text': `You have not earned the required ${gameElements[badgeId].name} Badge yet.`,
                    'class': 'red',
                    'id': RandomString(10)
                })
                access = false;
            }
        }
    }

    // check items
    if (element.opt_prereqItems) {
        for (let itemId of element.opt_prereqItems) {
            if (
                !playerData.items ||
                !playerData.items[itemId] || 
                playerData.items[itemId].count < 1
            ) {
                reasonsArr.push({
                    'text': `You do not own the required ${gameElements[itemId].name} Item.`,
                    'class': 'red',
                    'id': RandomString(10)
                });
                access = false;
            }
        }
    }

    // Check Teams
    if (
        element.opt_prereqTeams && 
        element.opt_prereqTeams.length > 0 && 
        element.opt_prereqTeams[0]
    ) {
        let teamFound = [];
        for (let teamId of element.opt_prereqTeams) {
          const team = teamMembers[teamId];
          if (!team || team.status !== 'active') continue;
          if (team.members.includes(playerData.playerId)) {
            teamFound.push(true);
          } else {
            teamFound.push(false);
          }
        }
        if (!teamFound.includes(true)) {
            reasonsArr.push({
                'text': `You are not on the required team(s) in order to access this ${type ?? 'element'}.`,
                'class': 'red',
                'id': RandomString(10)
            });
            access = false;
            hasTeam = false;
        }
    }

    let response = {
        'arr': reasonsArr,
        'access': access,
        'hasTeam': hasTeam
    }
    for (let remKey of Object.keys(remainingObj)) {
        let value = remainingObj[remKey]
        if (!isNaN(value)) {
            response[remKey] = value;
        }
    }

    if (!bool) {
        return response;
    } else {
        return access;
    }
}