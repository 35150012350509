import React, { useEffect, useState } from 'react'
import './status-table.styles.scss'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { useNavigate } from 'react-router-dom'
import { getPlayerDataFromDb } from '@/utils/getDataFromDb'

const PlayerStatusTable = ({ 
    gameData, 
    playerData, 
    gameElements, 
    playerActivity,
    role
}) => {

    const root = useSelector(state => state.userData.root);
    const [ itemCount, setItemCount ] = useState(0);
    const [ currentLevel, setCurrentLevel ] = useState(null);
    const [ completedChallenges, setCompletedChallenges] = useState([]);
    const [ manager, setManager ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!role) return;
        if (role === 'owner' || role === 'manager') {
            setManager(true);
        }
    }, [role])

    useEffect(() => {
        if (!playerData || !gameElements) return;
        if (playerData.levels) {
            const lastLevel = Object.values(playerData.levels).sort((a,b) => a.ts < b.ts ? 1 : -1)[0];
            const level = lastLevel.id;
            setCurrentLevel(level);
        } else {
            const levels = Object.values(gameElements)
            .filter(e => e.type === 'level' && e.status === 'active')
            .sort((a,b) => a.opt_pointMinimum > b.opt_pointMinimum ? 1 : -1)
            for (let l of levels) {
                if (playerData.points >= l.opt_pointMinimum) {
                    setCurrentLevel(l);
                }
            }
        }
    }, [playerData, gameElements])

    useEffect(() => {
        if (!playerActivity) return;
        const challengeActivity = Object.values(playerActivity).filter(a => a.type === 'approvedChallenge');
        setCompletedChallenges(challengeActivity);
    }, [playerActivity])

    useEffect(() => {
        if (!playerData || !gameElements) return;
        if (playerData.items) {
            console.log(playerData.items);
            let count = Object.values(playerData.items)
            .filter(i => 
                gameElements[i.id] &&
                gameElements[i.id].opt_itemType !== 'prizepack' &&
                gameElements[i.id].opt_itemType !== 'contribution' &&
                gameElements[i.id].opt_itemType !== 'lottery' &&
                gameElements[i.id].opt_itemType !== 'realworld'
            ).reduce((partialSum, a) => partialSum + a.count, 0)
            setItemCount(count);
        }
    }, [playerData, gameElements])
    
    return (
        <div className='inhab-status-table'>
            {
                (playerData && gameData) &&
                <table className='g-table no-header'>
                    <tbody>
                        {/* XP */}
                        {
                            (gameData.access.includes('points')) &&
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src='/images/xp.png' alt='xp icon' className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>My XP:</span>
                                </td>
                                <td>
                                    <span className='data-text'>{playerData.points ? playerData.points.toLocaleString() : '0'}</span>
                                </td>
                                <td className='shrink-cell'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => navigate('myxpearnings')}
                                    >
                                        <FontAwesomeIcon icon={faMagnifyingGlass} title='View all earnings' />
                                    </button>
                                </td>
                            </tr>
                        }
                        {/* BADGES */}
                        {
                            (gameData.access.includes('badges')) &&
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src='/images/icons/badges.png' alt='badges icon' className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>My Badges:</span>
                                </td>
                                <td>
                                    <span className='data-text'>
                                        {playerData.badges ? Object.values(playerData.badges).length : '0'}
                                        <span className='data-meta'> of {gameData.createdBadges ? gameData.createdBadges : '0'}</span>
                                    </span>
                                </td>
                                <td className='shrink-cell'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => navigate('mybadges')}
                                    >
                                        <FontAwesomeIcon icon={faMagnifyingGlass} title='View my badges' />
                                    </button>
                                </td>
                            </tr>
                        }
                        {/* LEVELS */}
                        {   
                            (gameData.access.includes('levels') && gameElements) &&
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src={
                                        (currentLevel && gameElements[currentLevel])
                                        ?
                                        gameElements[currentLevel].icon
                                        :
                                        `/images/unknown.png`
                                    } 
                                    alt='my level icon'
                                    className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>My Level:</span>
                                </td>
                                <td>
                                    <span className='data-text'>
                                        {
                                            (currentLevel && gameElements[currentLevel])
                                            ?
                                            gameElements[currentLevel].name
                                            :
                                            `No level yet...`
                                        }
                                    </span>
                                </td>
                                <td className='shrink-cell'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => navigate('mylevels')}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faMagnifyingGlass} 
                                            title='View all levels' 
                                        />
                                    </button>
                                </td>
                            </tr>
                        }
                        {/* CURRENT BALANCE */}
                        {
                            (gameData.access.includes('currency')) &&
                            <>
                                <tr>
                                    <td className='icon-td'>
                                    <ImageContainer src='/images/icons/coins.png' alt='currency icon' className='status-icon' />
                                    </td>
                                    <td>
                                        <span className='status-text'>My Current Balance:</span>
                                    </td>
                                    <td>
                                        <span className='data-text'>
                                            <div className='currency-cell'>
                                                <ImageContainer src='/images/icons/coin.png' alt='coin icon' className='currency-icon' />
                                                {
                                                    (playerData.currency)
                                                    ?
                                                    playerData.currency.toLocaleString()
                                                    :
                                                    0
                                                }
                                            </div>
                                        </span>
                                    </td>
                                    <td className='shrink-cell'>
                                        <button 
                                            className='g-button med-btn'
                                            onClick={() => navigate('mycurrency')}
                                        >
                                            <FontAwesomeIcon 
                                                icon={faMagnifyingGlass} 
                                                title='View my earnings' 
                                            />
                                        </button>
                                    </td>
                                </tr>
                            {/* TOTAL CURRENCY EARNED */}
                                <tr>
                                    <td className='icon-td'>
                                        <ImageContainer src='/images/icons/bank.png' alt='total currency icon' className='status-icon' />
                                    </td>
                                    <td>
                                        <span className='status-text'>My Total Earnings:</span>
                                    </td>
                                    <td>
                                        <span className='data-text'>
                                            <div className='currency-cell'>
                                                <ImageContainer src='/images/icons/coin.png' alt='coin icon' className='currency-icon' />
                                                {
                                                    (playerData.currencyTotal)
                                                    ?
                                                    playerData.currencyTotal.toLocaleString()
                                                    :
                                                    0
                                                }
                                            </div>
                                        </span>
                                    </td>
                                    <td className='shrink-cell'>
                                        <button 
                                            className='g-button med-btn'
                                            onClick={() => navigate('mytotalcurrency')}
                                        >
                                            <FontAwesomeIcon 
                                                icon={faMagnifyingGlass} 
                                                title='View my earnings' 
                                            />
                                        </button>
                                    </td>
                                </tr>
                            </>
                        }
                        {/* ITEMS */}
                        {
                            (gameData.access.includes('items')) &&
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src='/images/icons/backpack.png' alt='items icon' className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>My Item Count:</span>
                                </td>
                                <td>
                                    <span className='data-text'>
                                        {itemCount}
                                    </span>
                                </td>
                                <td className='shrink-cell'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => navigate('inventory')}    
                                    >
                                        <FontAwesomeIcon icon={faMagnifyingGlass} title='View my items' />
                                    </button>
                                </td>
                            </tr>
                        }
                        {/* SECRET CODES */}
                        {
                            (gameData.access.includes('secretCodes')) &&
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src='/images/icons/secret.png' alt='secret codes icon' className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>Codes I Found:</span>
                                </td>
                                <td>
                                    <span className='data-text'>
                                        {playerData.codes ? playerData.codes.length : '0'}
                                    </span>
                                </td>
                                <td className='shrink-cell'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => navigate('mysecretcodes')}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faMagnifyingGlass} 
                                            title='View my codes' 
                                        />
                                    </button>
                                </td>
                            </tr>
                        }
                        {/* CHALLENGES */}
                        {
                            (gameData.access.includes('challenges')) &&
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src='/images/icons/win.png' alt='challenges icon' className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>Completed Challenges:</span>
                                </td>
                                <td>
                                    <span className='data-text'>
                                        {completedChallenges.length}
                                        <span className='data-meta'> of {gameData.createdChallenges ? gameData.createdChallenges : '0'}</span>
                                    </span>
                                </td>
                                <td className='shrink-cell'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => navigate('mychallenges')}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faMagnifyingGlass} 
                                            title='View my challenges' 
                                        />
                                    </button>
                                </td>
                            </tr>
                        }
                        {/* QUESTS */}
                        {
                            (gameData.access.includes('quests')) && 
                            <tr>
                                <td className='icon-td'>
                                    <ImageContainer src='/images/icons/sidequest.png' alt='quests icon' className='status-icon' />
                                </td>
                                <td>
                                    <span className='status-text'>Completed Quests:</span>
                                </td>
                                <td>
                                    <span className='data-text'>
                                        
                                    </span>
                                </td>
                                <td className='shrink-cell'>
                                    <button className='g-button med-btn disabled'>View My Quests</button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            }
            <div className='g-space-0-5'></div>
        </div>
    )
}

export default PlayerStatusTable