import React, { useEffect, useRef, useState } from 'react'
import './secret-codes-form.styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ManageContentInformation from '../blocks/information.component';
import ManageContentIconCropped from '../blocks/icon.component';
import ManageContentCategories from '../blocks/categories.component';
import ManageContentPrerequisites from '../blocks/prerequisites.component';
import ManageContentDates from '../blocks/dates.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ManageContentRewards from '../blocks/rewards.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { RandomString } from '@/utils/randomString';
import ManageContentLimitations from '../blocks/limitations.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ManageContentVisibility from '../blocks/visibility.component';

const SecretCodesForm = ({ gameElements, gameData, playerList }) => {

    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ availableItems, setAvailableItems ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ teams, setTeams ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const { secretCodePath } = useParams();
    const itemRef = useRef();
    const dataRef = useRef();
    const location = useLocation();
    const approveRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();

    useEffect(() => {
        if (!gameData || !membership || !location) return;
        if (location.pathname.includes('/elements/secretcodes/edit')) return;
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
    }, [gameData, membership, location])

    useEffect(() => {
        if (!formData) {return;}
        if (!formData.icon) {
            // dataRef.current = {...dataRef.current, ...{'icon': {'url': '/images/icons/secret.png'}}};
            // setFormData(dataRef.current);
            saveData('icon', '/images/icons/secret.png')
        }
    }, [formData])

    useEffect(() => {
        if (!secretCodePath || !gameElements) {return;}
        dataRef.current = Object.values(gameElements).filter(e => e.path === secretCodePath)[0];
        setFormData(dataRef.current);
        // console.log(dataRef.current);
        if (dataRef.current.desc) {
            setExistingDescription(dataRef.current.desc);
        }
    }, [secretCodePath, gameElements])

    useEffect(() => {
        if (!gameElements) {return;}
        let arr = [];
        for (let c of Object.values(gameElements).filter(c => c.type === 'secretCode')) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
        }
        setCategories(arr.sort())
        let itemObj = {};
        Object.values(gameElements).filter(e => e.opt_itemType === 'inventory').map(e => itemObj[e.id] = e)
        console.log(itemObj);
        setAvailableItems({...itemObj});
    }, [gameElements])

    useEffect(() => {
        if (!playerList) return;
        const teamArr = Object.values(playerList)
        .filter(p => p.team);
        setTeams(teamArr);
    }, [playerList])

    function saveData(key, value) {
        let tempData = {...dataRef.current};
        if (!value) {
            delete tempData[key];
            dataRef.current = {...tempData};
        } else {
            dataRef.current = {...dataRef.current, ...{[key]: value}};
        }
        setFormData(dataRef.current);
    }
    
    function cancelCreate() {
        setConfirmMessage('Are you sure you want to cancel? All of your data will be lost.');
        approveRef.current = () => {
            dataRef.current = {'icon': {'url': '/images/icons/secret.png'}};
            setFormData(dataRef.current);
            navigate(-1)
        }
    }

    async function handleSubmit() {
        let tempData = {...dataRef.current}
        const reqs = ['name', 'icon']
        for (let r of reqs) {
            if (!tempData[r]) {
                setAlertMessage('Please include a name and an icon for this secret code before saving.');
                return;
            }
        }
        setConfirmMessage('Are you sure you are ready to save this secret code? All of these settings can be changed later.')
        approveRef.current = () => {
            finishApproval()
        }
    }

    async function finishApproval() {
        setLoading(true);
        let tempData = {...dataRef.current}
        console.log(tempData);
        if (tempData.icon.upload) {
			tempData.icon.croppie.result({
				type:'blob',
				size: {'width': 352, 'height': 352},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(tempData.icon, blob)
				return;
			})
		} else if (tempData.icon.url) {
			saveSecretCode(tempData.icon.url)
			return;
		} else if (tempData.icon) {
            saveSecretCode(tempData.icon)
            return; 
        } else {
			setAlertMessage('Something went wrong with the icon. Please try using a different image.');
            setLoading(false);
            return;
		}
    }

    function uploadImage(icon, blob) {		
		const now = new Date().getTime();
		const iconRef = ref(storage, `${gameData.gameId}/secretCodeIcons/${now}-${icon.name}`)
		const metadata = {
			contentType: icon.type,
            customMetadata: {
                appId: userProfile.appId,
                email: userProfile.email
            }
		};
		uploadBytes(iconRef, blob, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				savesecret-code(url)
				return;
			})
		})
	}

    async function saveSecretCode(iconUrl) {
        let tempData = {...dataRef.current}
        console.log(tempData);
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
        if (!tempData.id) {
            tempData.appId = userProfile.appId;
            tempData.ts_created = now;
            tempData.gameId = gameData.gameId;
            tempData.id = now + '-' + gameCode + '-secretCode';
            tempData.status = 'active';
            tempData.type = 'secretCode';
            tempData.opt_visible = true;
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            tempData.opt_available = true;
        } 
        tempData.ts = now;
        tempData.icon = iconUrl;
        tempData.code = tempData.name.toUpperCase().replace(/\s/gi,'');
        console.log(tempData);
        const res = await UseCloudFunction('saveGameElement', {'appId': userProfile.appId, 'elementData': tempData, 'userName': `${userProfile.fName} ${userProfile.lName}`})
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
            setLoading(false);
            return;
        }
        setAlertMessage('Secret Code saved successfully.');
        dataRef.current = {};
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='create-secret-code-form'>
            <div className='g-card secret-codes-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/secret2.png' alt='secret codes icon' className='head-icon' />
                    Create and Edit Secret Codes
                </div>
                <div className="g-space-1"></div>
                <ManageContentInformation 
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData} 
                    existingDescription={existingDescription} 
                    clearEditor={clearEditor} 
                    setClearEditor={setClearEditor}
                    textEditorLabel='Secret Code Description (optional)'
                />
                <div className='g-space-2'></div>
                <ManageContentIconCropped
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData} 
                />
                <div className='g-space-2'></div>
                
                <ManageContentCategories
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData} 
                    categories={categories}
                />
                <div className='g-space-2'></div>
                <ManageContentVisibility
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData} 
                    visibilityText="Turn visibility ON for this Secret Code. If toggled off, players can not redeem it, nor will it appear in their history."
                    availabilityText="This Secret Code is able to be redeemed by players if it is open according to the dates below and if the player meets any chosen prerequisites. If toggled off, players cannot redeem this Secret Code."
                    />
                <div className='g-space-2'></div>
                <ManageContentLimitations
                    type='secretCode'
                    saveData={saveData}
                    formData={formData} 
                />
                <div className='g-space-2'></div>
                <ManageContentPrerequisites
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData}
                    gameElements={gameElements} 
                    availableItems={availableItems}
                    teams={teams}
                />
                <div className='g-space-2'></div>
                <ManageContentRewards
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData} 
                    gameElements={gameElements}
                    text='finding this Secret Code'
                    gameData={gameData}
                />
                <div className='g-space-2'></div>
                <ManageContentDates
                    type='secretCode' 
                    saveData={saveData} 
                    formData={formData} 
                    gameElements={gameElements}
                    text='Secret Codes will NOT be available to be earned before or after these dates.'
                />
                <div className='g-space-2'></div>
                <span className='required'>* = Required</span>
                <div className='buttons'>
                    {
                        (loading) 
                        ?
                        <>
                            <button type='button' className='g-button'>Cancel</button>
                            <button type='button' className='g-button'><ThreeDotsLoader /></button>
                        </>
                        :
                        <>
                            <button type='button' className='g-button' onClick={() => cancelCreate()}>Cancel</button>
                            <button type='button' className='g-button submit-button' onClick={() => handleSubmit()}>Save Secret Code</button>
                        </>
                    }
                </div>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default SecretCodesForm