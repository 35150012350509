import React, { useEffect, useRef, useState } from 'react'
import './shopping-cart.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useDispatch, useSelector } from 'react-redux';
import CartItemCard from './store-components/cart-item-card.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { setShoppingCart } from '@/state/slices/userSlice';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import { UseCloudGameplay } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import CartContributionCard from './store-components/cart-contribution-card.component';
import { checkElementAccess } from '@/utils/checkElementAccess';

const ShoppingCart = ({ 
    gameData, 
    playerData, 
    gameElements, 
    gameStats,
    teamMembers
}) => {

    const shoppingCart = useSelector(state => state.userData.shoppingCart);
    const [ saleList, setSaleList ] = useState([]);
    const [ totalCost, setTotalCost ] = useState(0);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const approveRef = useRef();
    let accessInterval;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.getElementsByClassName('game-content')[0].scroll({'top': 0, 'behavior': 'smooth'});
        
        return () => {
            if (accessInterval) clearInterval(accessInterval);
        }
    }, [])

    useEffect(() => {
        if (!shoppingCart) return;
        const price = Object.values(shoppingCart)
        .reduce((acc, cur) => acc + (cur.quantity * cur.cost), 0)
        setTotalCost(price);
    }, [shoppingCart])

    useEffect(() => {
        if (!gameElements || !playerData || !shoppingCart) return;
        checkCartForAccess();
        accessInterval = setInterval(() => {
            checkCartForAccess();
        }, 10000)
    }, [gameElements, playerData, shoppingCart])

    function checkCartForAccess() {
        // console.log(shoppingCart);
        for (let itemId of Object.keys(shoppingCart)) {
            const access = checkElementAccess({
                'playerData': playerData,
                'gameElements': gameElements,
                'elementId': itemId,
                'teamMembers': teamMembers
            })
            // console.log(access);
            if (!access.access) {
                let tempCart = {...shoppingCart};
                delete tempCart[itemId]
                dispatch(setShoppingCart({...tempCart}))
            }
        }
    }

    useEffect(() => {
        if (!gameElements) return;
        getSales();
    }, [gameElements])

    function getSales() {
        const now = new Date().getTime();
        setSaleList(
            Object.values(gameElements)
            .filter(e => 
                e.type === 'sale' &&
                e.status === 'active' &&
                (!e.opt_dateOpen || e.opt_dateOpen < now) &&
                (!e.opt_dateClose || e.opt_dateClose > now)
            )
        )
    }

    function changeQuantity(quantity, itemId) {
        // console.log(quantity);
        if (quantity > 0) {
            let tempData = {...shoppingCart};
            let tempItem = {...tempData[itemId]};
            tempItem.quantity = quantity;
            dispatch(setShoppingCart({...tempData, ...{[itemId]: tempItem}}));
            return;
        }
    }

    function changeContribution(contribution, itemId) {
        let item = gameElements[itemId];
        if (contribution < 1) contribution = 1;
        if (item.opt_contributionMin && contribution < item.opt_contributionMin) {
            contribution = item.opt_contributionMin;
        }
        if (item.opt_contributionMax && contribution > item.opt_contributionMax) {
            contribution = item.opt_contributionMax;
        }
        if (contribution >= 1) {
            let tempData = {...shoppingCart};
            let tempItem = {...tempData[itemId]};
            tempItem.cost = contribution;
            dispatch(setShoppingCart({...tempData, ...{[itemId]: tempItem}}));
            return;
        }
        
    }

    function removeItem(itemId) {
        console.log(itemId)
        let tempData = {...shoppingCart};
        delete tempData[itemId];
        dispatch(setShoppingCart({...tempData}))
    }

    function confirmPurchase() {
        setConfirmMessage(`<h3>Are you sure you want to make this purchase?</h3><p>You will be spending <b>${totalCost.toLocaleString()}</b> coins which will leave you with <b>${(playerData.currency - totalCost).toLocaleString()}</b> coins.</p>`)
        approveRef.current = () => {
            continuePurchase();
        }

        async function continuePurchase() {
            setLoading(true);
            const res = await UseCloudGameplay(
                'processShoppingCartPurchase', 
                {
                    'gameId': gameData.gameId,
                    'playerId': playerData.playerId,
                    'cart': shoppingCart
                }
            )
            if (res.error) {
                setAlertMessage(`<p>Sorry, but your purchase couldn't go through. Here was the error message:</p><p>"${res.error}"</p>`);
                setLoading(false);
                return;
            }
            console.log(res);
            setAlertMessage('Purchase completed successfully!<br /><br />If you purchased a prize pack, it may take a minute or so to populate in your Rewards page.');
            dispatch(setShoppingCart({}));
            setLoading(false);
        }
    }

    return (
        <div className='shopping-cart'>
            <div className='g-card cart-card'>
                <BackButton cancel={() => navigate(-1)} />
                
                <div className='player-title'>
                    <ImageContainer src='/images/icons/shopping-cart.png' alt='marketplace icon' className='player-title-icon' />
                    My Shopping Cart
                </div>
                <hr />
                <section className='current-balance'>
                    <span>Current Balance: </span>
                    <ImageContainer src="/images/icons/coin.png" className='balance-coin' />
                    <span>{playerData.currency ? playerData.currency.toLocaleString() : 0}</span>
                </section>
                <div className="g-space-1"></div>
                <div className='cart-item-list'>
                    {
                        (shoppingCart && gameElements) &&
                        Object.values(shoppingCart).map(i => (
                            <div key={i.id}>
                                {
                                    (gameElements[i.id] && gameElements[i.id].opt_itemType === 'contribution')
                                    ?
                                    <CartContributionCard 
                                        cartItem={i} 
                                        itemData={gameElements[i.id]}
                                        changeContribution={changeContribution}
                                        removeItem={removeItem} 
                                    />
                                    :
                                    <CartItemCard 
                                        cartItem={i} 
                                        itemData={gameElements[i.id]}
                                        changeQuantity={changeQuantity}
                                        removeItem={removeItem} 
                                        saleList={saleList}
                                        gameElements={gameElements}
                                    />
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="g-space-2"></div>
                <div className="buttons">
                    <div className='total'>
                        Purchase Total: 
                        <ImageContainer src="/images/icons/coin.png" className='coin-icon' />
                        {totalCost.toLocaleString()}
                    </div>
                    {
                        (loading)
                        ?
                        <button className='g-button'><ThreeDotsLoader /></button>
                        :
                        <button 
                            className={`g-button ${
                                Object.values(shoppingCart).length > 0 &&
                                playerData.currency >= totalCost
                                    ? 'primary' 
                                    : 'disabled'
                            }`}
                            onClick={() => confirmPurchase()}
                        >Complete Purchase</button>
                    }
                </div>
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage}     
            />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
                buttonText='Complete Purchase'
                buttonClass='primary'
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default ShoppingCart