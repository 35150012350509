import React, { useEffect, useRef, useState } from 'react';
import './prize-packs-list.styles.scss';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import VisibilityButton from '../components/visibility-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { stripHtml } from '@/utils/sanitize';
import ManagerElementListMenu from '@/components/SHARED/manager-element-list-menu/manager-element-list-menu.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import ManagerElementListItem from '@/components/SHARED/manager-element-list-item/manager-element-list-item.component';

const PrizePacksList = ({
    gameData,
    gameElements
}) => {

    const membership = useSelector(state => state.userData.membership);
    const [ filteredElements, setFilteredElements ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ dropdown, setDropdown ] = useState(null);
    const [ menuCategory, setMenuCategory ] = useState('visible');
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();
    const catRef = useRef([]);

    useEffect(() => {
        chooseCategory();
    }, [filteredElements])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function chooseCategory(e) {
        if (e) {
            let catList = [...catRef.current];
            if (catList.includes(e)) {
                catList.splice(catList.indexOf(e), 1)
            } else {
                catList.push(e)
            }
            catRef.current = catList;
            setSelectedCategories(catRef.current);
        }
        if (catRef.current.length > 0) {
            setContentToDisplay(filteredElements.filter(a => catRef.current.includes(a.opt_cat)))
        } else {
            setContentToDisplay(filteredElements)
        }
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(filteredElements);
            return;
        }
        setContentToDisplay(filteredElements.filter(a => a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e)));
    }
    return (
        <div className='prize-packs-list'>
            <ManagerElementListMenu
                gameElements={gameElements}
                type='item'
                itemType='prizepack'
                setContentToDisplay={setContentToDisplay}
                setDropdown={setDropdown}
                setSelectedCategories={setSelectedCategories}
                setFilteredElements={setFilteredElements}
                setCategoryList={setCategoryList}
                menuCategory={menuCategory}
                setMenuCategory={setMenuCategory}
                catRef={catRef} />
            <div className='prize-packs-list-content'>
                <div className='prize-packs-list-search'>
                    <SearchBar search={search} />
                    
                    <button 
                        title='Create a New Level'
                        type='button' 
                        className='g-button med-btn' 
                        onClick={() => checkMembership()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                {/* <ItemSearchBar
                    availableItems={availableItems}
                    setItemsToDisplay={setContentToDisplay}
                    noTypes={true}
                    itemType='prizePack'
                /> */}
                <div className="g-space-1"></div>
                <div className='pp-list-div'>
                    <div className='g-list-item pp-list-head element-list-row meta'>
                        <div></div>
                        <div>Name</div>
                        <div>Description</div>
                        <div>Prize Count</div>
                        <div></div>
                    </div>
                    <div className='g-space-0-5'></div>
                    <div className='pp-list'>
                        {
                            (contentToDisplay.length > 0)
                            ?
                            contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => {
                                let itemCount = 0;
                                if (c.opt_prizes) {
                                    itemCount = c.opt_prizes
                                    .filter(e => 
                                        gameElements[e].status === 'active'
                                    ).length
                                }
                                return (
                                    <div key={c.id}>
                                        <ManagerElementListItem
                                            gameData={gameData}
                                            gameElements={gameElements}
                                            element={c}
                                            dropdown={dropdown}
                                            setDropdown={setDropdown}
                                            dataPoint={itemCount} />

                                    </div>
                                )
                            })
                            :
                            <p>No Prize Packs yet...</p>
                        }
                    </div>
                </div>
            </div>
            <CategoryFilterModal
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)}
                chooseCategory={chooseCategory} 
                categoryList={categoryList}
                selectedCategories={selectedCategories} 
                text='Filter by Category' 
                color='var(--primary)' />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default PrizePacksList