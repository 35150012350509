import React, { useEffect, useState } from 'react'
import './game-activity.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import DetailedActivityList from '@/components/SHARED/detailed-activity-list/detailed-activity-list.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const GameActivity = ({ 
    gameData,
    playerData,
    gameElements, 
    playerList,
    gameActivity
}) => {
    
    const [ activityToDisplay, setActivityToDisplay ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'View Game Activity'
        }
    }, [])

    useEffect(() => {
        if (!gameActivity) return;
        const avail = Object.values(gameActivity)
        .filter(a => 
            a.display === 'reward' || 
            a.display === 'playerActivity' || 
            a.display === 'purchase'
        )
        setActivityToDisplay([...avail]);
    }, [gameActivity]);

    return (
        <div className='game-activity-container'>
            <div className='game-activity-content g-card'>
                <div className='player-title'>
                    <ImageContainer src='/images/icons/list.png' alt='rewards icon' className='player-title-icon' />
                    My Activity
                </div>
                <hr />
                <DetailedActivityList
                    gameData={gameData}
                    playerData={playerData}
                    activity={activityToDisplay}
                    playerList={playerList}
                    gameElements={gameElements}
                />
            </div>
        </div>
    )
}

export default GameActivity