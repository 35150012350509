import React, { useEffect, useState } from 'react'
import './category-filter-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { properCase } from '@/utils/properCase';

const CategoryFilterModal = ({ 
    show, 
    cancel, 
    chooseCategory, 
    categoryList, 
    selectedCategories, 
    chooseType,
    typeList,
    selectedTypes,
    text, 
    color 
}) => {

    return (
        <div className='category-filter-modal'> 
            <Modal 
                show={show} 
                cancel={cancel} 
                closeButton={true} 
                cls='narrow' 
                text={text ?? 'Filter by Category'} >
                <div className='modal-children'> 
                    <div className='filter-list'>
                        {
                            (chooseType)
                            &&
                            <div><b>Categories</b></div>
                        }
                        {
                            (categoryList && categoryList.length > 0) 
                            ?
                            categoryList.sort((a,b) => {
                                if (a.name && a.name.toLowerCase() > b.name.toLowerCase()) {
                                    return 1;
                                } else if (a.name && a.name.toLowerCase() < b.name.toLowerCase()) {
                                    return 1;
                                } else if (typeof a === 'string' && a.toLowerCase() > b.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            })
                            .map(c => {
                                // console.log(c);
                                return (
                                <div key={c.id ?? c.playerId ?? c} className={`filter-button-div`}>
                                    <button 
                                        className='g-button med-btn' style={{
                                            'backgroundColor': selectedCategories && selectedCategories.includes(c.id ?? c.playerId ?? c) ? color ?? 'var(--primary)' : '',
                                            'color': selectedCategories && selectedCategories.includes(c.id ?? c.playerId ?? c) ? '#fff' : ''
                                        }} 
                                        onClick={() => chooseCategory(c.id ?? c.playerId ?? c)}
                                    >{c.displayName ?? c.name ?? c}</button>
                                </div>
                            )})
                            :
                            <div className='grey'>Nothing to filter yet...</div>
                        }
                        {
                            (chooseType) &&
                            <>
                                <div className="g-space-1"></div>
                                <div><b>Types</b></div>
                                <div className='filter-list'>
                                    {
                                        (typeList && typeList.length > 0) 
                                        ?
                                        typeList.sort((a,b) => {
                                            if (a.name && a.name.toLowerCase() > b.name.toLowerCase()) {
                                                return 1;
                                            } else if (a.name && a.name.toLowerCase() < b.name.toLowerCase()) {
                                                return 1;
                                            } else if (typeof a === 'string' && a.toLowerCase() > b.toLowerCase()) {
                                                return 1;
                                            } else {
                                                return -1;
                                            }
                                        })
                                        .map(t => {
                                            // console.log(c);
                                            return (
                                            <div key={t.id ?? t.playerId ?? t} className={`filter-button-div`}>
                                                <button 
                                                    className='g-button med-btn' style={{
                                                        'backgroundColor': selectedTypes && selectedTypes.includes(t.id ?? t.playerId ?? t) ? color ?? 'var(--primary)' : '',
                                                        'color': selectedTypes && selectedTypes.includes(t.id ?? t.playerId ?? t) ? '#fff' : ''
                                                    }} 
                                                    onClick={() => chooseType(t.id ?? t.playerId ?? t)}
                                                >{properCase(t)}</button>
                                            </div>
                                        )})
                                        :
                                        <div className='grey'>Nothing to filter yet...</div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className='buttons'>
                        <button className='g-button med-btn' onClick={cancel}>OK</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CategoryFilterModal