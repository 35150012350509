import React, { useRef, useState } from 'react';
import './element-duplicate.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { properCase } from '@/utils/properCase';
import { RandomString } from '@/utils/randomString';

const ElementDuplicate = ({
    gameElements,
    element,
    onComplete,
    style,
    classText
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const approveRef = useRef();

    async function duplicateElement() {
        setConfirmMessage('Are you sure you want to make a duplicate copy of this element?')
        approveRef.current = () => {
            continueDuplicate();
        }

        async function continueDuplicate() { 
            setLoading(true);
            console.log(element);
            const now = new Date().getTime();
            const gameCode = element.gameId.substring(element.gameId.indexOf('-')-5, element.gameId.indexOf('-')+6)
            let tempData = {...element};
            tempData.name = `Copy of ${element.name}`;
            tempData.ts_created = now;
            tempData.ts = now;
            tempData.id = now + '-' + gameCode + '-' + element.type;
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            if (tempData.ts_deleted) delete tempData.ts_deleted;
            if (tempData.ts_activated) delete tempData.ts_activated;
            if (tempData.opt_itemType === 'collectible') {
                const collections = Object.values(gameElements)
                .filter(e => 
                    e.type === 'collection' &&
                    e.opt_itemList.includes(element.id)
                )
                .map(e => e.id);
                tempData.opt_collections = [...collections];
            }
            const res = await UseCloudFunction(
                'saveGameElement', 
                {
                    'appId': userProfile.appId, 
                    'elementData': tempData, 
                    'userName': `${userProfile.fName} ${userProfile.lName}`
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
                setLoading(false);
                return;
            }
            setAlertMessage(`${properCase(tempData.type)} copied successfully.`);
            setLoading(false);
            if (onComplete) {
                onComplete();
            }
        }
    }

    return (
        <div className={`element-duplicate ${classText ?? ''}`}>
            {
                (style === 'button') &&
                (
                    (loading)
                    ?
                    <button
                        className='g-button med-btn'>
                        <ThreeDotsLoader />
                    </button>
                    : 
                    <button 
                        className='g-button med-btn'
                        onClick={() => duplicateElement()}>
                        Duplicate Element
                    </button>
                )
            }
            {
                (style === 'menu-item') &&
                (
                    (loading)
                    ?
                    <div className='element-menu-item'>
                        <ThreeDotsLoader />
                    </div>
                    :
                    <div 
                        className='element-menu-item'
                        onClick={() => duplicateElement()}>
                        <FontAwesomeIcon icon={faClone} />
                        Duplicate
                    </div>
                )
            }
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
        </div>
    )
}

export default ElementDuplicate