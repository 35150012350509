import React, { useRef, useState } from 'react'
import './manager-element-list-item.styles.scss';
import ImageContainer from '../image-container/image-container.component';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faEllipsisV, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { stripHtml } from '@/utils/sanitize';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import ElementDelete from '../element-delete/element-delete.component';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ElementDuplicate from '../element-duplicate/element-duplicate.component';
import UpgradeRequiredModal from '../upgrade-required-modal/upgrade-required-modal.component';

const ManagerElementListItem = ({ 
    gameElements,
    gameData,    
    element,
    dropdown,
    dataPoint,
    setDropdown
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();
    const approveRef = useRef();

    function checkMembership() {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        restoreInactive();
    }

    function restoreInactive() {
        setConfirmMessage('Are you sure you want to RESTORE this deleted Challenge? The Challenge will be restored with the same settings and data it had when it was deleted.');
        approveRef.current = () => {
            continueRestore();
        }

        async function continueRestore() { 
            setLoading(true);
            let tempData = {...element};
            const now = new Date().getTime();
            tempData.status = 'active';
            tempData.ts_activated = now;
            tempData.ts = now;
            const res = await UseCloudFunction(
                'saveGameElement', 
                {
                    'appId': userProfile.appId, 
                    'elementData': tempData, 
                    'userName': `${userProfile.fName} ${userProfile.lName}`,
                    'restored': true
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
                setLoading(false);
                return;
            }
            setAlertMessage('Challenge restored successfully.');
            setLoading(false);
        }
    }

    return (
        <div 
            className='manager-element-list-item g-list-item element-list-row'>
            <ImageContainer 
                src={element.icon} 
                alt={`${element.name} element icon`} 
                className={`element-icon ${
                    element.status !== 'active' 
                    ? 'disabled' 
                    : ''
                }`} />
            <div className='element-name'>
                {element.name}
            </div>
            <div className='meta'>
                {
                    element.desc && element.desc.length > 50
                    ?
                    stripHtml(element.desc).substring(0,50) + '...'
                    :
                    (element.desc)
                    ?
                    stripHtml(element.desc)
                    :
                    null
                }
            </div>
            <div className='meta element-purpose'>
                {dataPoint ?? null}
            </div>
            <div className='buttons'>    
                {/* <VisibilityButton element={c} /> */}
                {
                    (element.status === 'active')
                    ?
                    <>
                    {/* <button 
                        className='g-button med-btn' 
                        title='View badge data'
                        onClick={() => navigate(`/manage/${gameData.path}/elements/data/${element.path}`)}>
                        <FontAwesomeIcon icon={faChartPie} />
                    </button>
                    <button 
                        className='g-button med-btn' 
                        title='Edit this badge' 
                        onClick={() => navigate(`edit/${element.path}`)}  >
                        <FontAwesomeIcon icon={faPen} />
                    </button> */}
                    <div 
                        className={`element-dropdown ${
                            dropdown === element.id 
                            ? 'active' 
                            : ''
                        }`} >
                        <div className='element-dropdown-menu'>
                            <div 
                                className='element-menu-item'
                                onClick={() => navigate(`edit/${element.path}`)}>
                                <FontAwesomeIcon icon={faPen} />
                                Edit
                            </div>
                            <div 
                                className='element-menu-item'
                                onClick={() => navigate(`/manage/${gameData.path}/elements/data/${element.path}`)}>
                                <FontAwesomeIcon icon={faChartPie} />
                                Data
                            </div>
                            {/* <div className='element-menu-item'>
                                <FontAwesomeIcon icon={faCopy} />
                                Duplicate
                            </div> */}
                            <ElementDuplicate
                                gameElements={gameElements}
                                element={element}
                                style='menu-item' />
                            <ElementDelete 
                                element={element}
                                style='menu-item' />
                        </div>
                    </div>
                    <button
                        className='g-button med-btn more-button'
                        onClick={() => setDropdown(dropdown === element.id ? null : element.id)}>
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    
                    </>
                    :
                    (loading)
                    ?
                    <button className='g-button med-btn'>
                        <ThreeDotsLoader />
                    </button>
                    :
                    <button 
                        className='g-button med-btn'
                        onClick={() => checkMembership()}>
                        Restore
                    </button>
                }
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                messge={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
                closeButton={true} />
        </div>
    )
}

export default ManagerElementListItem