import React from 'react';
import './upload-code-preview.styles.scss';

const UploadCodePreview = ({ 
    code, 
    removeCode,
    gameElements 
}) => {
    return (
        <div className='g-list-item upload-code-preview'>
            <div className='preview-details'>
                <div className='title'>{code.name}</div>
                
                {
                    (code.desc) &&
                    <div className='meta'>{code.desc}</div>
                }
                <div className='details'>
                    {
                        (code.opt_cat) &&
                        <div className='meta'><b>Category:</b> {code.opt_cat}</div>
                    }
                </div>
                <div className='prereqs'>
                    {
                        (code.opt_prereqPoints) &&
                        <div className='meta'><b>Prerequisite Points:</b> {code.opt_prereqPoints.toLocaleString()}</div>
                    }
                    {
                        (code.opt_prereqLevel) &&
                        <div className='meta'><b>Prerequisite Level:</b> {code.opt_prereqLevel}</div>
                    }
                    {
                        (code.opt_prereqBadges && code.opt_prereqBadges.length > 0) &&
                        <div className='meta'><b>Prerequisite Badges:</b> {code.opt_prereqBadges.join(', ')}</div>
                    }
                    {
                        (code.opt_prereqItems && code.opt_prereqItems.length > 0) &&
                        <div className='meta'><b>Prerequisite Items:</b> {code.opt_prereqItems.join(', ')}</div>
                    }
                    {
                        (code.opt_prereqTeams && code.opt_prereqTeams.length > 0) &&
                        <div className='meta'><b>Prerequisite Teams:</b> {code.opt_prereqTeams.join(', ')}</div>
                    }
                </div>
                <div className='rewards'>
                    {
                        (code.opt_earnedPointsMin && code.opt_earnedPointsMax) 
                        ?
                        <div className='meta'><b>Min/Max Earned Points:</b> {
                            code.opt_earnedPointsMin.toLocaleString()}
                            /{
                            code.opt_earnedPointsMax.toLocaleString()}
                        </div>
                        :
                        (code.opt_earnedPointsMin)
                        ?
                        <div className='meta'><b>Earned Points:</b> {
                            code.opt_earnedPointsMin.toLocaleString()
                            }
                        </div>
                        :
                        (code.opt_earnedPointsMax)
                        ?
                        <div className='meta'><b>Earned Points:</b> {
                            code.opt_earnedPointsMax.toLocaleString()
                            }
                        </div>
                        : ''
                    }
                    {
                        (code.opt_earnedCurrencyMin && code.opt_earnedCurrencyMax)
                        ?
                        <div className='meta'>
                            <b>Min/Max Earned Currency:</b> {
                                code.opt_earnedCurrencyMin.toLocaleString()
                            }/{
                                code.opt_earnedCurrencyMax.toLocaleString()
                            }
                        </div>
                        :
                        (code.opt_earnedCurrencyMin)
                        ?
                        <div className='meta'>
                            <b>Earned Currency:</b> {
                                code.opt_earnedCurrencyMin.toLocaleString()
                            }
                        </div>
                        :
                        (code.opt_earnedCurrencyMax)
                        ?
                        <div className='meta'>
                            <b>Earned Currency:</b> {
                                code.opt_earnedCurrencyMax.toLocaleString()
                            }
                        </div>
                        : ''
                    }
                    {
                        (code.opt_earnedBadges && code.opt_earnedBadges.length > 0) &&
                        <div className='meta'><b>Earned Badges:</b> {code.opt_earnedBadges.join(', ')}</div>
                    }
                    {
                        (
                            code.opt_earnedItems && 
                            Object.values(code.opt_earnedItems).length > 0
                        ) &&
                        <div className='meta'><b>Earned Items:</b> {
                            Object.values(code.opt_earnedItems)
                            .map(i => gameElements[i.id].path).join(', ')
                        }</div>
                    }
                    {
                        (
                            code.opt_earnedPrizePacks && 
                            Object.values(code.opt_earnedPrizePacks).length > 0
                        ) &&
                        <div className='meta'><b>Earned Prize Packs:</b> {
                            Object.values(code.opt_earnedPrizePacks)
                            .map(i => gameElements[i.id].path).join(', ')
                        }</div>
                    }
                </div>
            </div>
            <div className='buttons'>
                <button 
                    className='g-button med-btn'
                    onClick={() => removeCode(code)}
                >Remove</button>
            </div>
        </div>
    )
}

export default UploadCodePreview