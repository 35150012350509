import React, { useEffect, useRef, useState } from 'react'
import './action-items.styles.scss';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBan, faBell, faCalendarDay, faCaretRight, faDownload, faFilter } from '@fortawesome/free-solid-svg-icons';
import CategoryFilterModal from '../create-elements/components/category-filter-modal.component';
import { format } from 'date-fns';
import ViewActionItem from './view-action-item.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import CsvDownload from 'react-json-to-csv';

const ActionItems = ({ 
    gameData, 
    gameElements, 
    playerList, 
    actionItems,
    role 
}) => {

    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ pendingCount, setPendingCount ] = useState(0);
    const [ playerFilter, setPlayerFilter ] = useState([]);
    const [ selectedPlayers, setSelectedPlayers ] = useState([]);
    const [ playerInfoFilter, setPlayerInfoFilter ] = useState([]);
    const [ selectedPlayerInfo, setSelectedPlayerInfo ] = useState([]);
    const [ elementFilter, setElementFilter ] = useState([]);
    const [ selectedElements, setSelectedElements ] = useState([]);
    const [ filterType, setFilterType ] = useState(null);
    const [ view, setView ] = useState('pending');
    const [ showDateFilter, setShowDateFilter ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const availableRef = useRef([]);
    const dataRef = useRef();
    const date1Ref = useRef();
    const date2Ref = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const startDateElement = document.getElementById('startDate');
        if (!date1Ref.current) {
            const flat1 = flatpickr(startDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('dateStart', date)
                    }
                }
            );
            date1Ref.current = flat1;
            // console.log('set fp')
        }
        const endDateElement = document.getElementById('endDate');
        if (!date2Ref.current) {
            const flat2 = flatpickr(endDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('dateEnd', date)
                    }
                }
            );
            date2Ref.current = flat2;
            // console.log('set date2')
        }
    }, [])

    useEffect(() => {
        if (!role) return;
        if (role !== 'owner' && role !== 'manager') {
            navigate(-1);
        }
    }, [role])
    
    useEffect(() => {
        if (!actionItems || !gameElements || !playerList || !view) return;
        let arr = [];
        let playerObj = {};
        let playerInfoArr = [];
        let elementObj = {};
        for (let i of Object.values(actionItems)
            .filter(i => 
                (view === 'complete' ? 
                (
                    i.status === 'approved' ||
                    i.status === 'declined'
                ) :
                i.status === 'pending')
                &&
                i.type !== 'realworld'
            )
            .sort((a,b) => a.ts > b.ts ? 1 : -1)) 
        {
            if (!gameElements[i.elementId] || !playerList[i.playerId]) {
                continue;
            }
            const obj = {
                'fName': playerList[i.playerId].fName,
                'lName': playerList[i.playerId].lName,
                'info': playerList[i.playerId].info,
                'playerId': i.playerId,
                'icon': gameElements[i.elementId].icon,
                'elementId': i.elementId,
                'element': gameElements[i.elementId].name,
                'ts': i.ts_submitted,
                'dateDisplay': format(new Date(i.ts_submitted), "Pp"),
                'id': i.id,
                'path': i.path,
                'type': i.type,
                'quantity': i.quantity
            }
            arr.push(obj);
            const player = {
                'name': obj.lName ? `${obj.lName}, ${obj.fName}` : obj.fName,
                'playerId': i.playerId
            }
            if (!playerObj[i.playerId]) playerObj[i.playerId] = player;
            if (!playerInfoArr.includes(obj.info)) playerInfoArr.push(obj.info);
            const element = {
                'name': obj.element,
                'id': i.elementId
            }
            if (!elementObj[i.elementId]) elementObj[i.elementId] = element;
        }
        availableRef.current = [...arr];
        setItemsToDisplay(availableRef.current);
        setPlayerFilter(Object.values(playerObj));
        setPlayerInfoFilter([...playerInfoArr]);
        setElementFilter(Object.values(elementObj));
    }, [actionItems, gameElements, playerList, view])
    
    useEffect(() => {
        if (!actionItems) return;
        setPendingCount(Object.values(actionItems).filter(i => 
            i.status === 'pending' &&
            i.type !== 'realworld'
        ).length)
    }, [actionItems])

    function saveData(key, value) {
        dataRef.current = ({
            ...dataRef.current,
            ...{[key]: value}
        })
        setFormData(dataRef.current);
    }

    useEffect(() => {
        filterData();
    }, [formData])

    function cancel() {
        navigate(`/manage/${gameData.path}`);
    }

    function switchView() {
        if (view === 'pending') {
            setView('complete');
        } else {
            setView('pending');
        }
        saveData('selectedPlayers', []);
        saveData('selectedPlayerInfo', []);
        saveData('selectedElements', []);
        date1Ref.current.clear();
        date2Ref.current.clear();
        setShowDateFilter(false)
    }

    function selectFilter(id) {
        if (filterType === 'player') {
            let tempPlayers = formData.selectedPlayers ? [...formData.selectedPlayers] : [];
            if (tempPlayers.includes(id)) {
                tempPlayers.splice(tempPlayers.indexOf(id), 1);
            } else {
                tempPlayers.push(id);
            }
            saveData('selectedPlayers', [...tempPlayers])
        } else if (filterType === 'playerInfo') {
            let tempPlayerInfo = formData.selectedPlayerInfo ? [...formData.selectedPlayerInfo] : [];
            if (tempPlayerInfo.includes(id)) {
                tempPlayerInfo.splice(tempPlayerInfo.indexOf(id), 1);
            } else {
                tempPlayerInfo.push(id);
            }
            saveData('selectedPlayerInfo', [...tempPlayerInfo]);
        } else if (filterType === 'element') {
            let tempElements = formData.selectedElements ? [...formData.selectedElements] : [];
            if (tempElements.includes(id)) {
                tempElements.splice(tempElements.indexOf(id), 1);
            } else {
                tempElements.push(id)
            }
            saveData('selectedElements', [...tempElements]);
        }
    }

    function filterData() {
        const filteredItems = availableRef.current.filter(a => {
            let meets = true;
            if (
                formData.selectedPlayers && 
                formData.selectedPlayers.length > 0 && 
                !formData.selectedPlayers.includes(a.playerId)
            ) {
                meets = false;
            }
            if (
                formData.selectedPlayerInfo && 
                formData.selectedPlayerInfo.length > 0 && 
                !formData.selectedPlayerInfo.includes(a.info)
            ) {
                meets = false;
            }
            if (
                formData.selectedElements && 
                formData.selectedElements.length > 0 && 
                !formData.selectedElements.includes(a.elementId)
            ) {
                meets = false;
            }
            if (
                formData.dateStart &&
                a.ts < formData.dateStart
            ) {
                meets = false;
            }
            if (
                formData.dateEnd &&
                a.ts > formData.dateEnd
            ) {
                meets = false;
            }
            return meets;
        })
        setItemsToDisplay([...filteredItems])
    }

    return (
        <div className='action-items-container'>
            <Routes>
                <Route path='' element={
                    <div className='action-items-content'>
                        <div className='g-card'>
                            <BackButton cancel={cancel} />
                            <div className="g-space-1"></div>
                            <div className='card-title'><FontAwesomeIcon icon={faBell} size='xl' />Action Items</div>
                            <hr />
                            <div className='action-item-grid'>
                                <div className='action-item-list-search'>
                                    {/* <SearchBar search={searchItems} /> */}
                                    <button 
                                        type='button' 
                                        className='g-button med-btn' 
                                        onClick={() => setFilterType('player')}>
                                        Filter by Player
                                        <FontAwesomeIcon 
                                            icon={faFilter}
                                            className={formData.selectedPlayers && formData.selectedPlayers.length > 0 ? 'full' : 'empty'} 
                                        />
                                    </button>
                                    <button 
                                        type='button' 
                                        className='g-button med-btn' 
                                        onClick={() => setFilterType('playerInfo')}>
                                        Filter by Player Info
                                        <FontAwesomeIcon 
                                            icon={faFilter}
                                            className={formData.selectedPlayerInfo && formData.selectedPlayerInfo.length > 0 ? 'full' : 'empty'} 
                                        />
                                    </button>
                                    <button 
                                        type='button' 
                                        className='g-button med-btn' 
                                        onClick={() => setFilterType('element')}>
                                        Filter by Element
                                        <FontAwesomeIcon 
                                            icon={faFilter}
                                            className={formData.selectedElements && formData.selectedElements.length > 0 ? 'full' : 'empty'} 
                                        />
                                    </button>
                                    <button 
                                        type='button'
                                        className='g-button med-btn'
                                        onClick={() => setShowDateFilter(!showDateFilter)} >
                                        <span className='button-text'>
                                            Filter by Date
                                            <FontAwesomeIcon icon={faCaretRight} className={`caret ${showDateFilter ? 'rotated' : ''}`} />
                                        </span>
                                        <FontAwesomeIcon
                                            icon={faCalendarDay}
                                            className={
                                                (
                                                    formData.dateStart || 
                                                    formData.dateEnd
                                                )
                                                ? 'full' 
                                                : 'empty'
                                            } />
                                    </button>
                                    <div className={`date-div ${!showDateFilter ? 'hidden' : ''}`}>
                                        <div className='field small'>
                                            <label className='clickable static clear-date'>
                                                Start Date
                                                <FontAwesomeIcon 
                                                    className='clear-date' 
                                                    icon={faBan}
                                                    onClick={() => date1Ref.current.clear()} />
                                            </label>
                                            <input className="flatpickr flatpickr-input"  id="startDate" placeholder='Start Date' />
                                        </div>
                                        <div className='g-space-0-5'></div>
                                        <div className='field small'>
                                            <label>
                                                End Date
                                                <FontAwesomeIcon 
                                                    className='clear-date' 
                                                    icon={faBan}
                                                    onClick={() => date2Ref.current.clear()} />
                                            </label>
                                            <input className="flatpickr flatpickr-input"  id="endDate" placeholder='End Date' />
                                        </div>
                                    </div>
                                </div>
                                <div className='action-item-table'>
                                    <div className='pending-count-buttons'>
                                        <div>Pending Items: {pendingCount}</div>
                                        <div className='buttons'>
                                            <button 
                                                type='button' 
                                                className='g-button small-btn' 
                                                onClick={() => switchView()} >
                                                Switch To {view === 'pending' ? 'Complete' : 'Pending'} Items
                                            </button>
                                            <CsvDownload 
                                                data={itemsToDisplay}
                                                filename={`ActionItems_${(format(new Date(), "Pp")).replace(/[^A-Za-z0-9]/g, '')}.csv`}
                                                className='g-button small-btn'
                                                delimiter=','
                                                title='Download CSV'
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                            </CsvDownload>
                                        </div>
                                    </div>
                                    <div className='g-space-0-5'></div>
                                    <div className='action-item-list'>
                                        <div className='g-list-item action-item-list-head'>
                                            <div></div>
                                            <div>Name</div>
                                            <div>Action</div>
                                            <div>Element</div>
                                            <div>Submitted</div>
                                            <div></div>
                                        </div>
                                        {
                                            (itemsToDisplay) &&
                                            itemsToDisplay.sort((a,b) => a.ts > b.ts ? 1 : -1)
                                            .map(i => (
                                                <div key={i.id} className='action-item g-list-item'>
                                                    <div className='action-item-icon'>
                                                        <ImageContainer src={i.icon} alt={`${i.element} icon`} />
                                                    </div>
                                                    <div className='action-item-username'>
                                                        {i.fName} {i.lName}
                                                    </div>
                                                    <div>
                                                        {
                                                            i.type === 'challenge' ?
                                                            'Challenge Completed' :
                                                            i.type === 'item' ?
                                                            'Item Used' :
                                                            ''
                                                        }
                                                    </div>
                                                    <div>
                                                        {i.element} {i.quantity && <span>(x{i.quantity})</span>}
                                                    </div>
                                                    <div>
                                                        {i.dateDisplay}
                                                    </div>
                                                    <div className='buttons'> 
                                                        {
                                                            (view === 'pending') &&
                                                            <button 
                                                                className='g-button small-btn' 
                                                                onClick={() => navigate(i.path)}
                                                            >
                                                                <FontAwesomeIcon 
                                                                    icon={faArrowRight} 
                                                                    size='xl' 
                                                                />
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            ))                        
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CategoryFilterModal 
                            show={filterType}
                            cancel={() => setFilterType(null)}
                            chooseCategory={selectFilter}
                            categoryList={
                                filterType === 'player' ? playerFilter :
                                filterType === 'playerInfo' ? playerInfoFilter :
                                filterType === 'element' ? elementFilter : null
                            }
                            selectedCategories={
                                filterType === 'player' ? formData.selectedPlayers : 
                                filterType === 'playerInfo' ? formData.selectedPlayerInfo : 
                                filterType === 'element' ? formData.selectedElements : null
                            }
                            text = {
                                filterType === 'player' ? 'Select a Player' :
                                filterType === 'playerInfo' ? 'Select Player Info' :
                                filterType === 'element' ? 'Select an Element' : null
                            }
                        />
                    </div>
                }></Route>
                <Route 
                    path=':itemPath' 
                    element={
                        <ViewActionItem 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList}
                            actionItems={actionItems} 
                        />
                    }
                ></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
            
        </div>
    )
}

export default ActionItems