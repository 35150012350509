import React, { useEffect, useRef, useState } from 'react'
import './challenges-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faFilter, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import { stripHtml } from '@/utils/sanitize';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VisibilityButton from '../components/visibility-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ManagerElementListItem from '@/components/SHARED/manager-element-list-item/manager-element-list-item.component';
import ManagerElementListMenu from '@/components/SHARED/manager-element-list-menu/manager-element-list-menu.component';

const ChallengesList = ({ gameElements, gameData }) => {
    
    const userProfile = useSelector(state => state.userData.userProfile);
    const membership = useSelector(state => state.userData.membership);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ filteredElements, setFilteredElements ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ categoryList, setCategoryList] = useState([]);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const [ menuCategory, setMenuCategory ] = useState('visible');
    const [ restoreLoading, setRestoreLoading ] = useState([]);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ dropdown, setDropdown ] = useState(null);
    const catRef = useRef([]);
    const approveRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        chooseCategory();
    }, [filteredElements])

    function checkMembership() {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function chooseCategory(e) {
        if (e) {
            let catList = [...catRef.current];
            if (catList.includes(e)) {
                catList.splice(catList.indexOf(e), 1)
            } else {
                catList.push(e)
            }
            catRef.current = catList;
            setSelectedCategories(catRef.current);
        }
        if (catRef.current.length > 0) {
            setContentToDisplay(filteredElements.filter(a => catRef.current.includes(a.opt_cat)))
        } else {
            setContentToDisplay(filteredElements)
        }
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(filteredElements.filter(a => a.type === 'challenge'))
            return;
        }
        setContentToDisplay(filteredElements.filter(a => a.type === 'challenge' && (a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e))))
    }
    
    return (
        <div className='challenge-list'>
            <ManagerElementListMenu
                gameElements={gameElements}
                type='challenge'
                setContentToDisplay={setContentToDisplay}
                setDropdown={setDropdown}
                setSelectedCategories={setSelectedCategories}
                setFilteredElements={setFilteredElements}
                setCategoryList={setCategoryList}
                menuCategory={menuCategory}
                setMenuCategory={setMenuCategory}
                catRef={catRef} />
            {/* <div className="g-space-2"></div> */}
            <div className='challenge-list-div'>
                <div className='challenge-list-search'>
                    <SearchBar search={search} />
                    <button 
                        type='button' 
                        className='g-button med-btn' 
                        title='Filter by Category'
                        onClick={() => setShowFilterModal(true)} >
                        <FontAwesomeIcon 
                            icon={faFilter}
                            className={selectedCategories.length > 0 ? 'full' : 'empty'} 
                        />
                    </button>
                    <button 
                        type='button' 
                        className='g-button med-btn' 
                        title='Create a New Challenge' 
                        onClick={() => checkMembership()} >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                
                <div className="g-space-1"></div>
                <div className='g-list-item challenge-list-head element-list-row meta'>
                    <div></div>
                    <div>Name</div>
                    <div>Instructions</div>
                    <div>Status</div>
                    <div></div>
                </div>
                <div className='g-space-0-5'></div>
                <div className='challenge-list'>
                    {
                        (contentToDisplay.length > 0)
                        ?
                        contentToDisplay
                        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(c => {
                            let now = new Date().getTime();
                            let status = 'Available';
                            if (!c.opt_available) {
                                status = 'Closed';
                            }
                            if (c.opt_dateOpen && now < c.opt_dateOpen) {
                                status = 'Closed';
                            }
                            if (c.opt_dateClose && now > c.opt_dateClose) {
                                status = 'Closed';
                            }
                            if (c.status !== 'active') {
                                status = 'Deleted';
                            }
                            return (
                            <div key={c.id}>
                                <ManagerElementListItem
                                    gameElements={gameElements}
                                    element={c}
                                    gameData={gameData}
                                    dropdown={dropdown}
                                    setDropdown={setDropdown}
                                    dataPoint={status} />
                                </div>
                            // <div key={c.id} className='g-list-item row'>
                            //     <div className='challenge-icon-div'>
                            //         <ImageContainer src={c.icon} alt={`${c.name} challenge icon`} className={`challenge-icon ${c.status !== 'active' ? 'disabled' : ''}`} />
                            //     </div>
                            //     <div className='challenge-name'>{c.name}</div>
                            //     <div className='challenge-desc meta'>{c.desc && stripHtml(c.desc).substring(0,60)}{c.desc && stripHtml(c.desc).length > 60 ? '...' : ''}</div>
                            //     {/* <div className='meta'>{c.opt_dateOpen ? format(new Date(c.opt_dateOpen), 'Pp') : format(new Date(c.created), 'Pp')}</div>
                            //     <div className='meta'>{c.opt_dateClose ? format(new Date(c.opt_dateClose), 'Pp') : 'Indefinite'}</div> */}
                            //     <div className='buttons'>    
                            //         {
                            //             (c.status === 'active')
                            //             ?
                            //             <>
                            //             <VisibilityButton element={c} />
                            //             <button 
                            //                 className='g-button med-btn' 
                            //                 title='View challenge data'
                            //                 onClick={() => navigate(`/manage/${gameData.path}/elements/data/${c.path}`)}
                            //             >
                            //                 <FontAwesomeIcon icon={faChartPie} />
                            //             </button>
                            //             <button className='g-button med-btn' onClick={() => navigate(`edit/${c.path}`)} title='Edit this challenge'><FontAwesomeIcon icon={faPen} /></button>
                            //             </>
                            //             :
                            //             (restoreLoading.includes(c.id))
                            //             ?
                            //             <button
                            //                 className='g-button med-btn'>
                            //                 <ThreeDotsLoader />
                            //             </button>
                            //             :
                            //             <button 
                            //                 className='g-button med-btn'
                            //                 onClick={() => restoreInactive(c)}>
                            //                 Restore
                            //             </button>
                            //         }
                            //     </div>
                            // </div>
                        )})
                        :
                        <p>No Challenges yet...</p>
                    }
                </div>
            </div>


            <CategoryFilterModal 
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--challenge-color)'
            />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default ChallengesList