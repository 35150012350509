import React, { useEffect, useRef, useState } from 'react';
import './action-items-realworld.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarDay, faCaretRight, faDownload, faFilter, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format, set } from 'date-fns';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import CategoryFilterModal from '../create-elements/components/category-filter-modal.component';
import CsvDownload from 'react-json-to-csv';

const ActionItemsRealWorld = ({
    gameData,
    gameElements, 
    playerList,
    actionItems
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ filterType, setFilterType ] = useState(null);
    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ playerFilter, setPlayerFilter ] = useState([]);
    const [ selectedPlayers, setSelectedPlayers ] = useState([]);
    const [ playerInfoFilter, setPlayerInfoFilter ] = useState([]);
    const [ selectedPlayerInfo, setSelectedPlayerInfo ] = useState([]);
    const [ elementFilter, setElementFilter ] = useState([]);
    const [ selectedElements, setSelectedElements ] = useState([]);
    const [ pendingCount, setPendingCount ] = useState(0);
    const [ view, setView ] = useState('pending');
    const [ showDateFilter, setShowDateFilter ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const dataRef = useRef();
    const date1Ref = useRef();
    const date2Ref = useRef();
    const availableRef = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        const startDateElement = document.getElementById('startDate');
        if (!date1Ref.current) {
            const flat1 = flatpickr(startDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('dateStart', date)
                    }
                }
            );
            date1Ref.current = flat1;
            // console.log('set fp')
        }
        const endDateElement = document.getElementById('endDate');
        if (!date2Ref.current) {
            const flat2 = flatpickr(endDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('dateEnd', date)
                    }
                }
            );
            date2Ref.current = flat2;
            // console.log('set date2')
        }
    }, [])

    useEffect(() => {
        if (!actionItems || !gameElements || !playerList || !view) return;
        let arr = []
        let playerObj = {};
        let playerInfoArr = [];
        let elementObj = {};
        for (let i of Object.values(actionItems).filter(i => 
                i.status === view &&
                i.type === 'realworld'
            )
        ) {
            if (!gameElements[i.elementId] || !playerList[i.playerId]) {
                continue;
            }
            const obj = {
                'fName': playerList[i.playerId].fName,
                'lName': playerList[i.playerId].lName,
                'playerId': i.playerId,
                'info': playerList[i.playerId].info,
                'icon': gameElements[i.elementId].icon,
                'elementId': i.elementId,
                'element': gameElements[i.elementId].name,
                'event': i.event,
                'ts': i.ts_submitted,
                'dateDisplay': format(new Date(i.ts_submitted), "P"),
                'id': i.id,
                'path': i.path,
                'type': i.type,
                'quantity': i.quantity,
                'status': i.status
            }
            arr.push(obj);
            const player = {
                'name': obj.lName ? `${obj.lName}, ${obj.fName}` : obj.fName,
                'playerId': i.playerId
            }
            if (!playerObj[i.playerId]) playerObj[i.playerId] = player;
            if (!playerInfoArr.includes(obj.info)) playerInfoArr.push(obj.info);
            const element = {
                'name': obj.element,
                'id': i.elementId
            }
            if (!elementObj[i.elementId]) elementObj[i.elementId] = element;
        }
        const sortedItems = arr.sort((a,b) => 
            a.lName.toLowerCase() > b.lName.toLowerCase() ?
            1 :  
            a.lName.toLowerCase() === b.lName.toLowerCase() ?
            a.fName.toLowerCase() > b.fName.toLowerCase() ? 
            1 : -1 : -1
        )
        availableRef.current = [...sortedItems];
        setItemsToDisplay(availableRef.current);
        setPlayerFilter(Object.values(playerObj));
        setPlayerInfoFilter([...playerInfoArr]);
        setElementFilter(Object.values(elementObj));
    }, [actionItems, gameElements, playerList, view])

    useEffect(() => {
        if (!actionItems) return;
        setPendingCount(Object.values(actionItems).filter(i => 
            i.status === 'pending' &&
            i.type === 'realworld'
        ).length)
    }, [actionItems])

    function switchView() {
        if (view === 'pending') {
            setView('approved');
        } else {
            setView('pending');
        }
        saveData('selectedElements', []);
        saveData('selectedPlayerInfo', []);
        saveData('selectedPlayers', []);
        date1Ref.current.clear();
        date2Ref.current.clear();
        setShowDateFilter(false)
    }

    function saveData(key, value) {
        dataRef.current = ({
            ...dataRef.current,
            ...{[key]: value}
        })
        setFormData(dataRef.current);
    }
    
    useEffect(() => {
        filterData();
    }, [formData])

    function selectFilter(id) {
        if (filterType === 'player') {
            let tempPlayers = formData.selectedPlayers ? [...formData.selectedPlayers] : [];
            if (tempPlayers.includes(id)) {
                tempPlayers.splice(tempPlayers.indexOf(id), 1);
            } else {
                tempPlayers.push(id);
            }
            saveData('selectedPlayers', [...tempPlayers]);
        } else if (filterType === 'playerInfo') {
            let tempPlayerInfo = formData.selectedPlayerInfo ? [...formData.selectedPlayerInfo] : [];
            if (tempPlayerInfo.includes(id)) {
                tempPlayerInfo.splice(tempPlayerInfo.indexOf(id), 1);
            } else {
                tempPlayerInfo.push(id);
            }
            saveData('selectedPlayerInfo', [...tempPlayerInfo]);
        } else if (filterType === 'element') {
            let tempElements = formData.selectedElements ? [...formData.selectedElements] : [];
            if (tempElements.includes(id)) {
                tempElements.splice(tempElements.indexOf(id), 1);
            } else {
                tempElements.push(id)
            }
            saveData('selectedElements', [...tempElements]);
        }
    }

    function filterData() {
        const filteredItems = availableRef.current.filter(a => {
            let meets = true;
            if (
                formData.selectedPlayers && 
                formData.selectedPlayers.length > 0 && 
                !formData.selectedPlayers.includes(a.playerId)
            ) {
                meets = false;
            }
            if (
                formData.selectedPlayerInfo && 
                formData.selectedPlayerInfo.length > 0 && 
                !formData.selectedPlayerInfo.includes(a.info)
            ) {
                meets = false;
            }
            if (
                formData.selectedElements && 
                formData.selectedElements.length > 0 && 
                !formData.selectedElements.includes(a.elementId)
            ) {
                meets = false;
            }
            if (
                formData.dateStart &&
                a.ts < formData.dateStart
            ) {
                meets = false;
            }
            if (
                formData.dateEnd &&
                a.ts > formData.dateEnd
            ) {
                meets = false;
            }
            return meets;
        })
        setItemsToDisplay([...filteredItems])
    }

    async function toggleDistributed(actionItemId) {
        const actionItem = actionItems[actionItemId];
        if (!actionItem) return;
        const res = await UseCloudFunction(
            'toggleDistributedRealWorldItem',
            {
                'actionItem': actionItem,
                'ts': new Date().getTime(),
                'status': actionItem.status === 'pending' ? 'approved' : 'pending',
                'appId': userProfile.appId,
                'approver_name': `${userProfile.fName} ${userProfile.lName}`
            }
        )
        console.log(res);
    }

    return (
        <div className='action-items-realworld'>
            <div className='action-items-content'>
                <div className='g-card'>
                    <BackButton cancel={() => navigate(-1)} />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        <FontAwesomeIcon icon={faShoppingBag} size='xl' />
                        Real World Item Distribution
                    </div>
                    <hr />
                    <div className='action-item-grid'>
                        <div className='action-item-list-search'>
                            {/* <SearchBar search={searchItems} /> */}
                            <button type='button' className='g-button med-btn' onClick={() => setFilterType('player')}>
                                Filter by Player
                                <FontAwesomeIcon 
                                    icon={faFilter}
                                    className={formData.selectedPlayers && formData.selectedPlayers.length > 0 ? 'full' : 'empty'} 
                                />
                            </button>
                            <button type='button' className='g-button med-btn' onClick={() => setFilterType('playerInfo')}>
                                Filter by Player Info
                                <FontAwesomeIcon 
                                    icon={faFilter}
                                    className={formData.selectedPlayerInfo && formData.selectedPlayerInfo.length > 0 ? 'full' : 'empty'} 
                                />
                            </button>
                            <button type='button' className='g-button med-btn' onClick={() => setFilterType('element')}>
                                Filter by Element
                                <FontAwesomeIcon 
                                    icon={faFilter}
                                    className={formData.selectedElements && formData.selectedElements.length > 0 ? 'full' : 'empty'} 
                                />
                            </button>
                            <button 
                                type='button'
                                className='g-button med-btn'
                                onClick={() => setShowDateFilter(!showDateFilter)} >
                                <span className='button-text'>
                                    Filter by Date
                                    <FontAwesomeIcon icon={faCaretRight} className={`caret ${showDateFilter ? 'rotated' : ''}`} />
                                </span>
                                <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    className={
                                        (
                                            formData.dateStart || 
                                            formData.dateEnd
                                        )
                                        ? 'full' 
                                        : 'empty'
                                    } />
                            </button>
                            <div className={`date-div ${!showDateFilter ? 'hidden' : ''}`}>
                                <div className='field small'>
                                    <label className='clickable static clear-date'>
                                        Start Date
                                        <FontAwesomeIcon 
                                            className='clear-date' 
                                            icon={faBan}
                                            onClick={() => date1Ref.current.clear()} />
                                    </label>
                                    <input className="flatpickr flatpickr-input"  id="startDate" placeholder='Start Date' />
                                </div>
                                <div className='g-space-0-5'></div>
                                <div className='field small'>
                                    <label>
                                        End Date
                                        <FontAwesomeIcon 
                                            className='clear-date' 
                                            icon={faBan}
                                            onClick={() => date2Ref.current.clear()} />
                                    </label>
                                    <input className="flatpickr flatpickr-input"  id="endDate" placeholder='End Date' />
                                </div>
                            </div>
                        </div>
                        {/* <div className="g-space-2"></div> */}
                        <div className='action-item-table'>
                            <div className='item-count-buttons'>
                                <div>Pending Items: {pendingCount}</div>
                                <div className='buttons'>
                                    <button type='button' className='g-button small-btn' onClick={() => switchView()}>
                                        Switch To {view === 'pending' ? 'Distributed' : 'Pending'} Items
                                    </button>
                                    <CsvDownload 
                                        data={itemsToDisplay}
                                        filename={`RealworldItems_${(format(new Date(), "Pp")).replace(/[^A-Za-z0-9]/g, '')}.csv`}
                                        className='g-button small-btn'
                                        delimiter=','
                                        title='Download CSV'
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </CsvDownload>
                                </div>
                            </div>
                            <div className='g-space-0-5'></div>
                            <table className='g-table action-item-list'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Info</th>
                                        <th>Element</th>
                                        <th>Date</th>
                                        <th>Event</th>
                                        <th className="td-center">Quantity</th>
                                        <th className="td-center">Distributed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    (itemsToDisplay) &&
                                    itemsToDisplay.map(i => (
                                        <tr key={i.id} className='action-item'>
                                            <td className='action-item-icon'>
                                                <ImageContainer src={i.icon} alt={`${i.element} icon`} />
                                            </td>
                                            <td className='action-item-username'>
                                                {i.fName} {i.lName}
                                            </td>
                                            <td>
                                                {i.info}
                                            </td>
                                            <td>
                                                {i.element}
                                            </td>
                                            <td>
                                                {i.dateDisplay}
                                            </td>
                                            <td>
                                                {i.event}
                                            </td>
                                            <td className='td-center'>
                                                {i.quantity}
                                            </td>
                                            <td className='td-center'> 
                                                {/* <button 
                                                    className='g-button small-btn' 
                                                    onClick={() => navigate(i.path)}
                                                >
                                                    <FontAwesomeIcon 
                                                        icon={faArrowRight} 
                                                        size='xl' 
                                                    />
                                                </button> */}
                                                <div className='td-center-div'>
                                                <button 
                                                    className='g-button text-only'
                                                    onClick={() => toggleDistributed(i.id)}
                                                >
                                                    {
                                                        (i.status === 'pending')
                                                        ?
                                                        <FontAwesomeIcon 
                                                            icon={faSquare} 
                                                            size='xl' 
                                                        />
                                                        :
                                                        <FontAwesomeIcon 
                                                            icon={faCheckSquare} 
                                                            size='xl' 
                                                            className='green'
                                                        />
                                                    }
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))                        
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <CategoryFilterModal 
                show={filterType}
                cancel={() => setFilterType(null)}
                chooseCategory={selectFilter}
                categoryList={
                    filterType === 'player' ? playerFilter :
                    filterType === 'playerInfo' ? playerInfoFilter :
                    filterType === 'element' ? elementFilter : null
                }
                selectedCategories={
                    filterType === 'player' ? formData.selectedPlayers : 
                    filterType === 'playerInfo' ? formData.selectedPlayerInfo : 
                    filterType === 'element' ? formData.selectedElements : null
                }
                text = {
                    filterType === 'player' ? 'Select a Player' :
                    filterType === 'playerInfo' ? 'Select Player Info' :
                    filterType === 'element' ? 'Select an Element' : null
                }
            />
        </div>
    )
}

export default ActionItemsRealWorld