import React, { useEffect, useRef, useState } from 'react';
import './store-item-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import QuantityInput from './quantity-input.component';
import { useDispatch, useSelector } from 'react-redux';
import { setShoppingCart } from '@/state/slices/userSlice';
import { checkElementAccess } from '@/utils/checkElementAccess';
import ItemPrice from '@/components/SHARED/item-price/item-price.component';
import { getCurrentSale } from '@/utils/getCurrentSale';
import { format } from 'date-fns';

const StoreItemCard = ({ 
    item, 
    playerData,
    gameStats,
    gameElements,
    teamMembers,
    saleList
}) => {

    const shoppingCart = useSelector(state => state.userData.shoppingCart);
    const [ quantityStart, setQuantityStart ] = useState(0); 
    const [ access, setAccess ] = useState(null);
    const [ remainingInd, setRemainingInd ] = useState(null);
    const [ remainingTotal, setRemainingTotal ] = useState(null);
    const [ max, setMax ] = useState(50);
    const quantityRef = useRef();
    const intervalRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current)
        }
    }, [])

    useEffect(() => {
        if (!gameElements || !item || !playerData) return;
        checkAccess();
        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            checkAccess();
        }, 10000)
    }, [gameElements, item, playerData, teamMembers])

    function checkAccess() {
        console.log('checking access');
        const accessResponse = checkElementAccess({
            'playerData': playerData,
            'gameElements': gameElements,
            'elementId': item.id,
            'gameStats': gameStats,
            'teamMembers': teamMembers
        });
        console.log(accessResponse)
        setAccess(accessResponse);
        // if (accessResponse) {
            
        //     let accessArr = [...accessResponse.arr]
        //     if (!isNaN(accessResponse.remainingInd) && accessResponse.remainingInd === 0) {
        //         accessArr.push('You are not able to own any more of this item.')
        //     } else if (!isNaN(accessResponse.remainingInd)) {
        //         setRemainingInd(accessResponse.remainingInd);
        //     }
        //     if (!isNaN(accessResponse.remainingTotal) && accessResponse.remainingTotal === 0) {
        //         accessArr.push('There are no more of these items available.')
        //     } else if (!isNaN(accessResponse.remainingTotal)) {
        //         setRemainingTotal(accessResponse.remainingTotal);
        //     }
        //     setAccess(accessArr)
        // };
        // console.log(accessArr);
    }

    function changeQuantity(quantity) {
        // console.log(quantity);
        if (quantity === 0) {
            let tempData = {...shoppingCart};
            delete tempData[item.id];
            dispatch(setShoppingCart({...tempData}));
            return;
        }
        let maxCount;
        if (remainingInd && remainingTotal) {
            if (remainingInd < remainingTotal) {
                maxCount = remainingInd;
             } else {
                maxCount = remainingTotal;
             }
        } else if (remainingInd) {
            maxCount = remainingInd;
        } else if (remainingTotal) {
            maxCount = remainingTotal;
        }
        if (maxCount) {
            setMax(maxCount);
        }
        quantityRef.current = quantity;
        addItemToCart();
    }

    function addItemToCart() {
        // console.log(quantityRef.current);
        let tempCart = {...shoppingCart};
        const saleData = getCurrentSale(item, saleList)
        let price = item.opt_marketPrice;
        if (saleData && saleData.newPrice) {
            price = saleData.newPrice;
        }
        const obj = {
            "quantity": quantityRef.current,
            "id": item.id,
            "cost": price,
        }
        if (saleData) {
            obj.sale = saleData.sale.id;
        }
        // console.log(obj);
        if (tempCart && tempCart[item.id]) {
            if (quantityRef.current === 0) {
                delete tempCart[item.id];
            } else {
                let tempItem = {...tempCart[item.id]};
                const newTotal = tempItem.quantity = quantityRef.current;
                tempItem.quantity = newTotal;
                tempCart[item.id] = {...tempItem};
                console.log(tempCart[item.id]);
            }
        } else if (tempCart) {
            tempCart[item.id] = obj;
        } else {
            tempCart = {
                [item.id]: obj,
            }
        }
        // console.log(tempCart[item.id]);
        dispatch(setShoppingCart({...tempCart}));
        setQuantityStart(1);
        // console.log(tempCart);
    }

    return (
        <div className='store-item-card g-list-item '>
            <div className='store-item-grid'>
                <div className='store-item-icon-div'>
                    <ImageContainer src={item.icon} className='item-icon' />
                </div>
                <div className='store-item-text-div'>
                    <div className='item-name'>{item.name}</div>
                    <div className='item-desc'>{item.desc}</div>
                    <div className='item-price'>
                        Price: 
                        <ItemPrice 
                            item={item} 
                            saleList={saleList} 
                            gameElements={gameElements}
                        />
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (item.opt_itemType === 'lottery') &&
                        <div className='item-desc'>
                            Tickets Sold: {gameStats[item.id] ? gameStats[item.id].toLocaleString() : 0}
                        </div> 
                    }
                    {
                        (remainingInd) &&
                        <div className='meta'>
                            You can own {remainingInd} more of this item.
                        </div>
                    }
                    {
                        (remainingTotal) &&
                        <div className='meta'>
                            There are {remainingTotal} of these items still available.
                        </div>
                    }
                    {
                        (item.opt_dateClose && item.opt_dateClose > new Date().getTime()) &&
                        <div className='meta'>
                            Element closes on {format(new Date(item.opt_dateClose), "Pp")}
                        </div>
                    }
                    {
                        (access && !access.access && access.arr && access.arr.length > 0)
                        ?
                        <div className='access-list'>
                            {
                                access.arr.map(a => (
                                    <div
                                        key={a.id} 
                                        className='access-item meta'
                                    >
                                        {a.text}
                                    </div>
                                ))
                            }
                        </div>
                        :
                        (access && access.access)
                        ?
                        <div className='buttons'>
                            <QuantityInput 
                                initialValue={(shoppingCart && shoppingCart[item.id]) ? shoppingCart[item.id].quantity : quantityStart}
                                max={max}
                                onQuantityChange={changeQuantity} 
                            />
                            {/* <button 
                                className='g-button med-btn'
                                onClick={() => addItemToCart()}
                            >
                                Add to Cart
                            </button> */}
                        </div>
                        : 
                        <div></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StoreItemCard