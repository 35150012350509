import React, { useEffect, useRef, useState } from 'react'
import './slot-machine.styles.scss';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import Modal from '@/components/modal/modal.component';

const SlotMachine = ({
    tickets,
    setShowWinner,
    setWinner
}) => {

    const [ ticketsToDisplay, setTicketsToDisplay ] = useState(null);
    const [ isScrolling, setIsScrolling ] = useState(false);
    const listRef = useRef(null);

    useEffect(() => {
        if (!tickets) return;
        const list = listRef.current;
        list.scrollTop = 0;
        setTicketsToDisplay([]);
        let shuffledTickets = [
            ...[{
                'displayName': 'Click Here',
                'icon': '/images/icons/turn-down.png'
            }],
            ...tickets.sort((a, b) => 0.5 - Math.random()) 
        ]
        setTicketsToDisplay(shuffledTickets);
        console.log(shuffledTickets[shuffledTickets.length-1]);
    }, [tickets])

    useEffect(() => {
        const list = listRef.current;
        if (!list) return;

        const scrollToBottom = () => {
            const totalScrollTime = 7000; // 5 seconds in milliseconds
            const totalScrollDistance = list.scrollHeight; 

            let scrollTop = 0;
            let elapsedTime = 0;
            const intervalId = setInterval(() => {
                elapsedTime += 10; // Interval time

                // Calculate a smooth easing function (e.g., ease-out-expo)
                const t = Math.min(elapsedTime / totalScrollTime, 1); 
                let easedProgress;

                if (t === 0) {
                    easedProgress = 0;
                } else {
                    easedProgress = 1 - Math.pow(2, -10 * t); 
                }

                scrollTop = easedProgress * totalScrollDistance; 
                list.scrollTop = scrollTop;

                if (elapsedTime >= totalScrollTime) {
                    clearInterval(intervalId);
                    setIsScrolling(false); 
                    console.log('done');
                    setWinner(ticketsToDisplay[ticketsToDisplay.length-1])
                    setShowWinner(true);
                }
            }, 10); 
        };

        if (isScrolling) {
        scrollToBottom();
        }
    }, [isScrolling, tickets]);

    const handleScrollButtonClick = () => {
        let shuffledTickets = [
            ...[{
                'displayName': 'Click Here',
                'icon': '/images/icons/turn-down.png'
            }],
            ...tickets.sort((a, b) => 0.5 - Math.random()) 
        ]
        setTicketsToDisplay(shuffledTickets);
        console.log(shuffledTickets[shuffledTickets.length-1]);
        setIsScrolling(true);
    };

    return (
        <div className='slot-machine-container'>
            
            <div 
                ref={listRef} 
                className='g-list-item ticket-list'
                style={{'overflowY': 'hidden'}}>
                {
                    (ticketsToDisplay) && 
                    ticketsToDisplay.map((ticket, index) => (
                        <div 
                            key={index}
                            className='ticket-display'>
                            <UserIcon profile={ticket} small={true} noBorder={true} noHover={true} />
                            {ticket.displayName}
                        </div>
                    ))
                }
            </div>
            <div className="g-space-1"></div>
            <button 
                className='g-button'
                onClick={handleScrollButtonClick}>
                Pick a Winner
            </button>
            
        </div>
    );        
}

export default SlotMachine;