import React from 'react'
import './upload-item-preview.styles.scss';
import { format } from 'date-fns';

const UploadItemPreview = ({ item, removeItem }) => {

    return (
        <div className='g-list-item upload-item-preview'>
            <div className='preview-details'>
                <div className='title'>{item.name} | <span className='meta'>{item.opt_itemType}</span></div>
                
                {
                    (item.desc) &&
                    <div className='meta'>{item.desc}</div>
                }
                <div className='details'>
                    {
                        (item.opt_cat) &&
                        <div className='meta'><b>Category:</b> {item.opt_cat}</div>
                    }
                    
                    <div className='meta'><b>Visible:</b> {item.opt_visible ? 'True' : 'False'}</div>
                    <div className='meta'><b>Preview:</b> {item.opt_preview ? 'True' : 'False'}</div>
                    {
                        (item.opt_limitInd) &&
                        <div className='meta'><b>Player Limit:</b> {item.opt_limitInd}</div>
                    }
                    {
                        (item.opt_limitTotal) &&
                        <div className='meta'><b>Game Limit:</b> {item.opt_limitTotal}</div>
                    }
                    {
                        (item.opt_dateOpen) &&
                        <div className='meta'><b>Date Available:</b> {format(new Date(item.opt_dateOpen), 'Pp')}</div>
                    }
                    {
                        (item.opt_dateClose) &&
                        <div className='meta'><b>Date Closed:</b> {format(new Date(item.opt_dateClose), 'Pp')}</div>
                    }
                </div>
                <div className='market'>
                    {
                        (item.opt_market) &&
                        <div className='meta'><b>Available in the Store:</b> {item.opt_market ? 'True': 'False'}</div>
                    }
                    {
                        (item.opt_marketPrice) &&
                        <div className='meta'><b>Store Price:</b> {item.opt_marketPrice}</div>
                    }
                    {
                        (item.opt_resalePrice) &&
                        <div className='meta'><b>Buy-Back Price:</b> {item.opt_resalePrice}</div>
                    }
                </div>
                <div className='prereqs'>
                    {
                        (item.opt_prereqPoints) &&
                        <div className='meta'><b>Prerequisite Points:</b> {item.opt_prereqPoints.toLocaleString()}</div>
                    }
                    {
                        (item.opt_prereqLevel) &&
                        <div className='meta'><b>Prerequisite Level:</b> {item.opt_prereqLevel}</div>
                    }
                    {
                        (item.opt_prereqBadges && item.opt_prereqBadges.length > 0) &&
                        <div className='meta'><b>Prerequisite Badges:</b> {item.opt_prereqBadges.join(', ')}</div>
                    }
                    {
                        (item.opt_prereqItems && item.opt_prereqItems.length > 0) &&
                        <div className='meta'><b>Prerequisite Items:</b> {item.opt_prereqItems.join(', ')}</div>
                    }
                    {
                        (item.opt_prereqTeams && item.opt_prereqTeams.length > 0) &&
                        <div className='meta'><b>Prerequisite Teams:</b> {item.opt_prereqTeams.join(', ')}</div>
                    }
                </div>
                <div className='rewards'>
                    {
                        (item.opt_earnedPoints) &&
                        <div className='meta'><b>Earned Points if Used:</b> {item.opt_earnedPoints.toLocaleString()}</div>
                    }
                    {
                        (item.opt_earnedCurrency) &&
                        <div className='meta'><b>Earned Currency if Used:</b> {item.opt_earnedCurrency.toLocaleString()}</div>
                    }
                    {
                        (item.opt_earnedBadges && item.opt_earnedBadges.length > 0) &&
                        <div className='meta'><b>Earned Badges if Used:</b> {item.opt_earnedBadges.join(', ')}</div>
                    }
                    {
                        (item.opt_earnedItems && item.opt_earnedItems.length > 0) &&
                        <div className='meta'><b>Earned Items if Used:</b> {item.opt_earnedItems.join(', ')}</div>
                    }
                    {
                        (item.opt_earnedPrizePacks && item.opt_earnedPrizePacks.length > 0) &&
                        <div className='meta'><b>Earned Prize Packs if Used:</b> {item.opt_earnedPrizePacks.join(', ')}</div>
                    }
                </div>
                {
                    (item.opt_collections) && 
                    <div className='meta'><b>Collection(s):</b> {item.opt_collections.join(', ')}</div>
                }
            </div>
            <div className='buttons'>
                <button 
                    className='g-button med-btn'
                    onClick={() => removeItem(item)}
                >Remove</button>
            </div>
        </div>
    )
}

export default UploadItemPreview