import React, { useEffect, useRef, useState } from 'react'
import './items-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faFilter, faPen, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityButton from '../components/visibility-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import ItemsUploadModal from './items-upload-modal.component';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import ManagerElementListMenu from '@/components/SHARED/manager-element-list-menu/manager-element-list-menu.component';
import ManagerElementListItem from '@/components/SHARED/manager-element-list-item/manager-element-list-item.component';
import { properCase } from '@/utils/properCase';

const ItemsList = ({ 
    gameElements, 
    gameData 
}) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ filteredElements, setFilteredElements ] = useState([]);
    const [ availableItems, setAvailableItems ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ typeList, setTypeList ] = useState([]);
    const [ selectedTypes, setSelectedTypes ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ dropdown, setDropdown ] = useState(null);
    const [ menuCategory, setMenuCategory ] = useState('visible');
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const searchRef = useRef();
    const catRef = useRef();
    const typeRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        catRef.current = [];
        typeRef.current = []
    }, [])

    useEffect(() => {
        if (!gameElements) {return;}
        const avail = Object.values(gameElements).filter(a => 
            a.type === 'item' && 
            a.opt_itemType !== 'prizepack'
        );
        setContentToDisplay([...avail]);
        setAvailableItems([...avail]);
    }, [gameElements])

    useEffect(() => {
        search();
    }, [selectedCategories, selectedTypes])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate(e);
    }

    function chooseCategory(cat) {
        // console.log(cat);
        let tempCats = [...selectedCategories];
        // console.log(tempCats)
        if (tempCats.includes(cat)) {
            tempCats.splice(tempCats.indexOf(cat), 1)
        } else {
            tempCats.push(cat);
        }
        setSelectedCategories([...tempCats]);
    }

    function chooseType(type) {
        // console.log(type);
        let tempTypes = [...selectedTypes];
        if (tempTypes.includes(type)) {
            tempTypes.splice(tempTypes.indexOf(type), 1)
        } else {
            tempTypes.push(type);
        }
        setSelectedTypes([...tempTypes]);
    }

    function search(text) {
        // if (!text) resetItems();
        searchRef.current = text;
        setContentToDisplay(
            filteredElements
            .filter(e => 
                (
                    text 
                    ? 
                    e.name.toLowerCase().includes(text.toLowerCase())
                    :
                    true
                ) &&
                (
                    selectedCategories.length > 0
                    ?
                    selectedCategories.includes(e.opt_cat)
                    :
                    true
                ) &&
                (
                    selectedTypes.length > 0
                    ?
                    selectedTypes.includes(e.opt_itemType)
                    :
                    true
                ) 
            )
        )
    }

    return (
        <div className='item-list'>
            <ManagerElementListMenu
                gameElements={gameElements}
                type='item'
                setContentToDisplay={setContentToDisplay}
                setDropdown={setDropdown}
                setSelectedCategories={setSelectedCategories}
                setFilteredElements={setFilteredElements}
                setCategoryList={setCategoryList}
                setTypeList={setTypeList}
                menuCategory={menuCategory}
                setMenuCategory={setMenuCategory}
                catRef={catRef} />
                 
            <div className='item-list-content'>
                <div className='item-list-search'>
                    <SearchBar search={search} />
                    <button 
                        type='button' 
                        className='g-button med-btn' 
                        title='Filter by Category'
                        onClick={() => setShowFilterModal(true)} >
                        <FontAwesomeIcon 
                            icon={faFilter}
                            className={
                                selectedCategories.length > 0 ||
                                selectedTypes.length > 0
                                ? 'full' 
                                : 'empty'
                            } 
                        />
                    </button>
                    <button
                        type='button'
                        className='g-button med-btn'
                        title='Upload a List of Items'
                        onClick={() => checkMembership('upload')} >
                        <FontAwesomeIcon icon={faUpload} />
                    </button>
                    <button 
                        type='button' 
                        className='g-button med-btn' 
                        title='Create a New Item' 
                        onClick={() => checkMembership('create')} >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="g-space-1"></div>
                <div className='item-list-div'>
                    <div className='g-list-item item-list-head element-list-row meta'>
                        <div></div>
                        <div>Name</div>
                        <div>Description</div>
                        <div>Type</div>
                        <div></div>
                    </div>
                    <div className='g-space-0-5'></div>
                    <div className='item-list'>
                        {
                            (contentToDisplay.length > 0)
                            ?
                            contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => (
                                <div key={c.id}>
                                    <ManagerElementListItem
                                        gameElements={gameElements}
                                        gameData={gameData}
                                        element={c}
                                        dropdown={dropdown}
                                        setDropdown={setDropdown}
                                        dataPoint={properCase(c.opt_itemType)} />

                                </div>
                            ))
                            :
                            <p>No items yet...</p>
                        }
                    </div>
                </div>
            </div>
            <CategoryFilterModal
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)}
                chooseCategory={chooseCategory} 
                categoryList={categoryList}
                selectedCategories={selectedCategories} 
                chooseType={chooseType}
                typeList={typeList}
                selectedTypes={selectedTypes}
                text='Filter by Category or Item Type' 
                color='var(--primary)' />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default ItemsList