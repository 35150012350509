import React, { useEffect } from 'react'
import './leaderboard-landing-page.styles.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import LeaderboardList from './leaderboard-list.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import CreateLeaderboard from './create-leaderboard.component';

const LeaderboardsLandingPage = ({ gameData, gameElements, playerList }) => {

    const navigate = useNavigate();
    const location = useLocation();

    function cancel() {
        // if (location.pathname.endsWith('/leaderboards')) {
        //     navigate(`/manage/${gameData.path}`)
        // } else {
            navigate(-1);
        // }
    }

    return (
        <div className='manage-leaderboards-container'>
            <div className='manage-leaderboards-content g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/leaderboard.png' className='head-icon' />
                    Create and Edit Leaderboards</div>
                <hr />
                <Routes>
                    <Route path='' element={<LeaderboardList gameElements={gameElements} gameData={gameData} />}></Route>
                    <Route path='create' element={<CreateLeaderboard gameData={gameData} gameElements={gameElements} playerList={playerList} />}></Route>
                    <Route path='edit/:leaderboardId' element={<CreateLeaderboard gameData={gameData} gameElements={gameElements} playerList={playerList} />}></Route>
                </Routes>
            </div>    
        </div>
    )
}

export default LeaderboardsLandingPage