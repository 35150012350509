import React, { useEffect, useRef, useState } from 'react'
import './collections-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faFilter, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityButton from '../components/visibility-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import ManagerElementListMenu from '@/components/SHARED/manager-element-list-menu/manager-element-list-menu.component';
import ManagerElementListItem from '@/components/SHARED/manager-element-list-item/manager-element-list-item.component';

const CollectionsList = ({ gameElements, gameData }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ filteredElements, setFilteredElements ] = useState([]);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]); 
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ dropdown, setDropdown ] = useState(null);
    const [ menuCategory, setMenuCategory ] = useState('visible');
    const catRef = useRef([]);
    const navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //     if (!gameElements) {return;}
    //     setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'collection'));
    //     setCategoryList([...new Set(Object.values(gameElements).filter(c => c.type === 'collection' && c.opt_cat).map(c => c.opt_cat))])
    // }, [gameElements])

    useEffect(() => {
        chooseCategory();
    }, [filteredElements])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function chooseCategory(e) {
        if (e) {
            let catList = [...catRef.current];
            if (catList.includes(e)) {
                catList.splice(catList.indexOf(e), 1)
            } else {
                catList.push(e)
            }
            catRef.current = catList;
            setSelectedCategories(catRef.current);
        }
        if (catRef.current.length > 0) {
            setContentToDisplay(filteredElements.filter(a => catRef.current.includes(a.opt_cat)))
        } else {
            setContentToDisplay(filteredElements)
        }
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(filteredElements);
            return;
        }
        setContentToDisplay(filteredElements.filter(a => a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e)));
    }

    return (
        <div className='collection-list'>
            <ManagerElementListMenu
                gameElements={gameElements}
                type='collection'
                setContentToDisplay={setContentToDisplay}
                setDropdown={setDropdown}
                setSelectedCategories={setSelectedCategories}
                setFilteredElements={setFilteredElements}
                setCategoryList={setCategoryList}
                menuCategory={menuCategory}
                setMenuCategory={setMenuCategory}
                catRef={catRef} />
            <div className='collection-list-content'>
                <div className='collection-list-search'>
                    <SearchBar search={search} />
                    <button 
                        type='button' 
                        className='g-button med-btn' 
                        onClick={() => setShowFilterModal(true)}>
                        <FontAwesomeIcon 
                            icon={faFilter}
                            className={
                                selectedCategories.length > 0 
                                ? 'full' 
                                : 'empty'
                            } 
                        />
                    </button>
                    <button 
                        type='button' 
                        className='g-button med-btn' 
                        onClick={() => checkMembership()}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="g-space-1"></div>
                <div className='collection-list-div'>
                    <div className='g-list-item collection-list-head element-list-row meta'>
                        <div></div>
                        <div>Name</div>
                        <div>Description</div>
                        <div>Element Count</div>
                        <div></div>
                    </div>
                    <div className='g-space-0-5'></div>
                    <div className='collection-list'>
                        {
                            (contentToDisplay.length > 0)
                            ?
                            contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => {
                                let itemCount = 0;
                                if (c.opt_itemList) {
                                    itemCount = c.opt_itemList
                                    .filter(e => gameElements[e].status === 'active').length;
                                }
                                return (
                                    <div key={c.id} >
                                        <ManagerElementListItem
                                            gameElements={gameElements}
                                            gameData={gameData}
                                            element={c}
                                            dropdown={dropdown}
                                            setDropdown={setDropdown}
                                            dataPoint={itemCount} />
                                    </div>
                                )
                            })
                            :
                            <p>No collections yet...</p>
                        }
                    </div>
                </div>
            </div>

            <CategoryFilterModal 
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--collection-color)'
            />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default CollectionsList