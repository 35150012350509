import React, { useEffect, useRef, useState } from 'react';
import './element-data-display.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate, useParams } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { faArrowsUpToLine, faBoxOpen, faCoins, faCubesStacked, faInfinity, faPen, faPercentage, faRefresh } from '@fortawesome/free-solid-svg-icons';
import DataCard from '../../game-dashboard/data-card.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import Chart3 from '../../game-dashboard/chart3.component';
import { format } from 'date-fns';
import { sanitize } from '@/utils/sanitize';
import { formatNumber } from '@/utils/formatNumber';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const ElementDataDisplay = ({
    gameData,
    gameStats,
    gameElements,
    playerList,
    gameActivity
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ element, setElement ] = useState(null);
    const [ totalOwned, setTotalOwned ] = useState(null);
    const [ totalAllowed, setTotalAllowed ] = useState(null);
    const [ remaining, setRemaining ] = useState(0);
    const [ showLimitModal, setShowLimitModal ] = useState(false);
    const [ newLimit, setNewLimit ] = useState(0);
    const [ itemActivity, setItemActivity ] = useState([]);
    const [ owners, setOwners ] = useState(null);
    const [ elementTypePath, setElementTypePath ] = useState(null);
    const [ showAvailable, setShowAvailable ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const { elementPath } = useParams();
    const approveRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!elementPath || !gameElements) return;
        let elmt = Object.values(gameElements)
        .filter(e => e.path === elementPath)[0];
        setElement(elmt);
        setNewLimit(elmt.opt_limitTotal ?? 0);
        if (elmt.type === 'item' && elmt.opt_limitTotal) {
            setShowAvailable(true);
        }
    },[elementPath, gameElements])

    useEffect(() => {
        if (!gameStats || !element) return;
        setTotalOwned(gameStats[element.id] ?? 0);
        if (!element.opt_limitTotal) {
            setRemaining(<FontAwesomeIcon icon={faInfinity} />);
            return;
        }
        let rem = element.opt_limitTotal - gameStats[element.id];
        setRemaining(rem);
    },[gameStats, element])

    useEffect(() => {
        if (!element || !gameActivity) return;
        let actArr = Object.values(gameActivity)
        .filter(a => 
            a.elementId === element.id ||
            (
                a.data &&
                Object.values(a.data)
                .filter(d => d.id === element.id)
                .length > 0
            )
        )
        console.log(actArr);
        setItemActivity([...actArr])
    }, [element, gameActivity])

    useEffect(() => {
        if (!element || !playerList) return;
        console.log(element);
        let type = element.type + 's';
        if (element.opt_itemType === 'prizepack') {
            type = element.opt_itemType + 's';
        }
        setElementTypePath(type);
        let players = Object.values(playerList)
        .filter(p => 
            !p.team &&
            p.status === 'active' &&
            p[type] &&
            p[type][element.id] &&
            p[type][element.id].count > 0
        )
        let playersWithDate = [];
        for (let p of players) {
            let newPlayer = {...p};
            newPlayer.lastDate = itemActivity
            .filter(a => a.playerId === p.playerId).length > 0
            ?
            new Date(
                itemActivity
                .filter(ac => ac.playerId === p.playerId)
                .sort((a,b) => a.ts < b.ts ? 1 : -1)[0].ts
            )
            : null
            playersWithDate.push(newPlayer);
        }
        setOwners([...playersWithDate])
        if (element.type === 'badge') {
            setTotalAllowed(
                Object.values(playerList)
                .filter(p => !p.team && p.status === 'active')
                .length
            )
        } else if (element.opt_limitTotal) {
            setTotalAllowed(element.opt_limitTotal)
        } else {
            setTotalAllowed(<FontAwesomeIcon icon={faInfinity} />)
        }
    }, [element, playerList, itemActivity])

    function resetContributions() {
        setConfirmMessage(`Are you sure you want to reset the contribution total to zero? Your players' contribution history will not change. This action cannot be undone.`);
        approveRef.current = () => {
            continueReset();
        }

        async function continueReset() {
            setLoading(true);
            const res = await UseCloudFunction(
                'resetContributionTotal',
                {
                    'appId': userProfile.appId,
                    'item': element,
                    'ts': new Date().getTime()
                }
            )
            console.log(res);
            setLoading(false);
            if (res.error) {
                setAlertMessage('Something went wrong: ' + res.error);
                return;
            } 
            setAlertMessage('Contributions successfully reset to zero.');
        }
    }

    return (
        <div className='element-data-display'>
            <div className='element-data-content g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                {
                    (element) &&
                    <div className='content'>
                        <div className='title-box with-icon'>
                            <ImageContainer 
                                src={element.icon} 
                                className='title-icon'
                            />
                            <div className='title-text'>
                                <div className='card-title'>
                                    {element.name}
                                </div>
                                <div className="g-space-0-5"></div>
                                <div className='meta'>
                                    <div dangerouslySetInnerHTML={{__html: element.desc}}></div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={`card-row ${
                            (
                                showAvailable ||
                                element.opt_itemType === 'contribution'
                            ) 
                            ? 'four-cards' 
                            : ''
                        }`}>
                            <DataCard 
                                icon={faCubesStacked}
                                data={totalOwned}
                                text='Owned/ Earned'
                            />
                            <DataCard
                                icon={faArrowsUpToLine}
                                data={totalAllowed}
                                text='Total Allowed'
                            />
                            <DataCard
                                icon={faPercentage}
                                data={
                                    (element.opt_itemType === 'contribution' && gameStats[element.id + '_contributions'])
                                    ?
                                    ((gameStats[element.id + '_contributions'] / element.opt_contributionGoal) * 100).toFixed(0) + '%'
                                    :
                                    (!isNaN(totalAllowed)) ?
                                    ((totalOwned / totalAllowed) * 100).toFixed(0) + '%' :
                                    '0%'
                                }
                                text={
                                    element.opt_itemType === 'contribution' 
                                    ? 'Contributed' 
                                    : 'Earned'
                                }
                            />
                            {
                                (element.opt_itemType === 'contribution')
                                ?
                                <DataCard
                                    icon={faCoins}
                                    data={formatNumber(gameStats[element.id + '_contributions'])}
                                    text={`of ${element.opt_contributionGoal.toLocaleString()}`} />
                                :
                                (showAvailable) &&
                                <DataCard
                                    icon={faBoxOpen}
                                    data={remaining}
                                    text='Available'
                                />
                            }
                            
                        </div>
                        <div className="g-space-1"></div>
                        {
                            (element.type === 'item') &&
                            <div className='buttons'>
                                {
                                    (element.opt_itemType === 'contribution') &&
                                    <button 
                                        className='g-button small-btn'
                                        onClick={() => resetContributions()}>
                                        <FontAwesomeIcon icon={faRefresh} />
                                        Reset Contributions to Zero
                                    </button>
                                }
                                <button
                                    className='g-button small-btn'
                                    onClick={() => navigate(`../items/edit/${element.path}`)}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                    Edit Element
                                </button>
                            </div>
                        }
                        {
                            (itemActivity) &&
                            <>
                            <div className='g-space-2'></div>
                            <div className=''>
                                Recent Activity
                            </div>
                            <div className='graph-row'>
                                <Chart3 
                                    recentActivity={itemActivity}
                                    chartHeight='175' 
                                />
                            </div>
                            </>
                        }
                        <div className='g-space-2'></div>
                        <div className='player-div'>
                            <div className=''>Player List</div>
                            <div className='g-space-1'></div>
                            <div className='player-list'>
                                {
                                    (owners) &&
                                    owners
                                    .sort((a,b) => a.lastDate < b.lastDate ? 1 : -1)
                                    .map(p => (
                                        <button 
                                            key={p.playerId}
                                            className={`g-button player-item med-btn ${p[elementTypePath][element.id].totalSpent ? 'four-wide' : ''}`} 
                                            onClick={() => navigate(`/manage/${gameData.path}/players/${p.path}`)}
                                        >
                                            <div className='name-div'>
                                                {
                                                (p.icon)
                                                ?
                                                <ImageContainer src={p.icon} />
                                                :
                                                <div 
                                                    className='gradient' 
                                                    style={{'background': p.gradient}}>
                                                    {p.fName[0]}
                                                </div>
                                                }
                                                {p.lName ? `${p.lName}, ${p.fName}` : p.fName}
                                            </div>
                                            
                                            {
                                                (itemActivity) &&
                                                <div className='activity meta'>
                                                    
                                                    Last Activity: {format(new Date(p.lastDate), "P")}
                                                </div>
                                            }
                                            {
                                                (elementTypePath) &&
                                                <div className='count'>
                                                    Count: {p[elementTypePath][element.id].count}
                                                </div>
                                            }
                                            {
                                                (p[elementTypePath][element.id].totalSpent) &&
                                                <div className='total'>
                                                    Total: <ImageContainer src='/images/icons/coin.png' className='coin-icon' />{formatNumber(p[elementTypePath][element.id].totalSpent)}
                                                </div>
                                            }
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal
                show={showLimitModal}
                cancel={() => setShowLimitModal(false)}
                closeButton={true}
                cls='narrow'
                text='Change Game-Wide Limit'
            >
                <div className='limit-modal-content'>
                    <div className='field'>
                        <label>Change Total Limit</label>
                        <input 
                            type='number'
                            min='0'
                            value={
                                newLimit ??
                                0
                            }
                            onChange={(e) => setNewLimit(Number(e.target.value))}
                        />
                    </div>
                </div>
            </Modal>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default ElementDataDisplay;