import React, { useRef, useState } from 'react';
import './element-delete.styles.scss';
import { useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';

const ElementDelete = ({
    element,
    onComplete,
    style,
    classText
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const approveRef = useRef();
    
    function markAsInactive() {
        if (!element) return;
        setConfirmMessage('Are you sure you want to mark this Challenge as DELETED? This will remove it from your game. You can restore deleted Challenges from the "Deleted" section.');
        approveRef.current = () => {
            continueDelete();
        }

        async function continueDelete() { 
            setLoading(true);
            let tempData = {...element};
            const now = new Date().getTime();
            tempData.status = 'inactive';
            tempData.ts_deleted = now;
            tempData.ts = now;
            const res = await UseCloudFunction(
                'saveGameElement', 
                {
                    'appId': userProfile.appId, 
                    'elementData': tempData, 
                    'userName': `${userProfile.fName} ${userProfile.lName}`,
                    'removed': true
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
                setLoading(false);
                return;
            }
            setAlertMessage('Challenge deleted successfully.');
            setLoading(false);
            if (onComplete) {
                onComplete();
            }
        }
    }

    return (
        <div className={`element-delete ${classText ?? ''}`}>
            {
                (style === 'button') &&
                (
                    (loading)
                    ?
                    <button className='g-button small-btn'>
                        <ThreeDotsLoader />
                    </button>
                    :
                    <button 
                        type='button' 
                        className={`g-button small-btn red`}
                        onClick={() => markAsInactive()}>
                        Delete Element
                    </button>
                )
            }
            {
                (style === 'menu-item') &&
                (
                    (loading)
                    ?
                    <div className='element-menu-item'>
                        <ThreeDotsLoader />
                    </div>
                    :
                    <div 
                        className='element-menu-item'
                        onClick={() => markAsInactive()} >
                        <FontAwesomeIcon icon={faTrash} />
                        Delete
                    </div>
                )
            }
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default ElementDelete