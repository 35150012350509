import React, { useEffect, useState } from 'react'
import './items-landing.styles.scss';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ItemsList from './items-list.component';
import ItemsForm from './items-form.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useSelector } from 'react-redux';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { properCase } from '@/utils/properCase';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import ItemsUploadModal from './items-upload-modal.component';
import ItemsUploadPage from './items-upload-page.component';

const ItemsLandingPage = ({ gameElements, gameData, playerList }) => {

    const membership = useSelector(state => state.userData.membership);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const [ showUploadModal, setShowUploadModal ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkMembership();
    }, [])

    function checkMembership() {
        const element = 'items'
        const res = testMemberRestrictions({'gameData': gameData, 'membership': membership, 'element': element})
        // console.log(res);

        if (res.disabled) {
            let message = ''
            const planRest = membershipRestrictions[membership.membership];
            if (res.disabledReason.includes(element)) {
                message = `While using the ${planRest.title}, you are not able to access ${properCase(element)}.`;
            }
            setShowUpgradeModal(message)
        }
    }

    function checkMembershipForNewItem(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    return (
        <div className='items-landing'>
            <Routes>
                <Route path='' element={
                    <div className='g-card items-card'>
                        <BackButton cancel={() => navigate(-1)} />
                        <div className="g-space-1"></div>
                        <div className='card-title'>
                            <ImageContainer 
                                src='/images/icons/items.png' 
                                alt='items icon' 
                                className='head-icon' 
                            />
                            Create and Edit Items
                            {/* <div className='buttons'>
                                <button 
                                    type='button' 
                                    className='g-button med-btn' 
                                    onClick={() => checkMembershipForNewItem()}
                                >
                                    Create a New Item
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button 
                                    className='g-button med-btn'
                                    title='Upload a List of Items'
                                    onClick={() => navigate('upload')}
                                >
                                    <FontAwesomeIcon icon={faUpload} />
                                </button>
                            </div> */}
                        </div>
                        <hr />
                        <div className='items-landing-content'>
                            <ItemsList gameElements={gameElements} gameData={gameData} />
                        </div>
                    </div>
                }></Route>
                <Route path='upload' element={
                    <ItemsUploadPage
                        gameData={gameData}
                        gameElements={gameElements}
                    />
                }></Route>
                <Route path='create' element={
                    <ItemsForm 
                        gameElements={gameElements} 
                        gameData={gameData} 
                        playerList={playerList} 
                    />
                }></Route>
                <Route path='edit/:itemPath' element={
                    <ItemsForm 
                        gameElements={gameElements} 
                        gameData={gameData} 
                        playerList={playerList} 
                    />
                }></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
            <ItemsUploadModal
                show={showUploadModal}
                cancel={() => setShowUploadModal(false)}
                gameData={gameData}
            />
        </div>
    )
}

export default ItemsLandingPage