import { RandomString } from "./randomString";

export function getRewardsFromElement(element, gameElements) {
    let rewardArr = [];
    for (let key of Object.keys(element)) {
        if (!key.includes('earned')) continue;
        if (key === 'opt_earnedPoints') {
            rewardArr.push({
                'id': RandomString(10),
                'icon': '/images/xp.png',
                'quantity': element[key],
                'title': `${element[key]} Points Earned`,
                'type': 'points'
            })
        }
        if (key === 'opt_earnedCurrency') {
            rewardArr.push({
                'id': RandomString(10),
                'icon': '/images/icons/coin.png',
                'quantity': element[key],
                'title': `$${element[key]} Earned`,
                'type': 'currency'
            })
        }
        if (key === 'opt_earnedItems' || key === 'opt_earnedPrizePacks') {
            let elArr = Object.values(element[key]);
            for (let earnedEl of elArr) {
                const element = gameElements[earnedEl.id];
                if (!element) continue;
                rewardArr.push({
                    'id': RandomString(10),
                    'icon': element.icon,
                    'quantity': earnedEl.quantity,
                    'title': `${
                        element.type === 'prizepack' 
                        ? 'Prize Pack' 
                        : 'Item'
                    } Earned: ${element.name}`,
                    'elementId': earnedEl.id,
                    'type': element.type
                })
            }
        }
    }
    console.log(rewardArr);
    return rewardArr
}